<div class="aems-bread-crumb m-b-16">
    <a class="m-r-4" routerLink="/workorders">Work Orders</a><span>/ {{id}}</span>
</div>
<div class="m-b-12" style="display: flex;align-items: center;gap:12px">
    <h3>Work Order-{{id}}</h3>
    <mc-tag fit="small" [label]="workOrderStatus()" appearance="info">
    </mc-tag>
    @if(workOrder?.reeferContainerCondition==='BOX_OK'){
    <mc-tag fit="small" icon="check-circle" appearance="success" label="BOX OK">
    </mc-tag>
    }
    @if(workOrder?.reeferContainerCondition==='MAC_OK'){
    <mc-tag fit="small" icon="check-circle" appearance="success" label="MAC OK">
    </mc-tag>
    }

    <div style="display: flex;flex-grow: 1;justify-content: end;gap: 8px;">
        @if(workOrder?.maintenanceOrderStatusCode===390 && authPolicy.canEditWorkOrder ){
        <mc-button data-cy="mark-complete" fit="small" (click)="onMarkCompleteClicked()"
        [disabled]="markAsCompleteAPICallStatus === 'IN_PROGRESS'" [loading]="markAsCompleteAPICallStatus=='IN_PROGRESS'">Mark Complete</mc-button>
        }
        @if([10,100,200,330,340,390].includes(workOrder?.maintenanceOrderStatusCode??0) && authPolicy.canEditWorkOrder){
        <mc-button data-cy="edit" fit="small" variant="outlined" icon="pencil" (click)="onEditClicked()">Edit</mc-button>
        }
        @if(authPolicy.canViewAuditLog && workOrder){
        <mc-button data-cy="audit-log" fit="small" variant="outlined" icon="arrow-anti-clockwise-clock" (click)="onAuditLogClicked()">Audit
            Log</mc-button>
        }
    </div>
</div>
@if(viewDataApiCalls==="COMPLETED_SUCCESFULLY"){
@if(workOrder){ 
<app-work-order-header [workOrderHeader]="workOrder"></app-work-order-header>
}

@if(equipment){
<app-cwo-container-details [equipment]="equipment"
    [shopLocationCode]="workOrder?.locationCode"></app-cwo-container-details>
}
@if(workOrder && workOrder.maintenanceOrderLines && blobFilesResolved()){
<div class="m-t-12">
    <app-list-repair-line-items [data]="workOrder.maintenanceOrderLines"
        [maintainceOrderStatus]="workOrder.maintenanceOrderStatusCode" shopCurrency="NA"></app-list-repair-line-items>
</div>
}
@if(workOrder?.blobResolvedFiles?.length??-1>0){
<div class="aems-card m-t-12">
    <p class="mds-content"><strong>Attachments</strong></p>
    <hr class="m-b-12" />
    <app-file-upload type="full" [readOnly]="true" [inputFiles]="workOrder?.blobResolvedFiles??[]"></app-file-upload>
</div>
}@else{
<div class="aems-card m-t-12">
    <p class="mds-content">No Attachments</p>

</div>
}
@if(totalCost){
<div class="m-t-12">

    <app-cwo-total-cost [cost]="totalCost" [totalManHoursSummary]="totalManHoursSummary"
        [workOrderMode]="'view'"></app-cwo-total-cost>
</div>}
<div class="m-t-12">
    <app-cwo-remarks [workOrderId]="id" [workOrderRemarks]="remarks" [workOrderMode]="'view'"></app-cwo-remarks>
</div>
<div class="m-t-12" style="display:flex;justify-content: end;gap:16px">
    <mc-button fit="medium" variant="outlined" (click)="onBackClicked()">Back</mc-button>
    @if([100,200,330,390].includes(workOrder?.maintenanceOrderStatusCode??0)){
    <mc-button data-cy="delete" fit="medium" variant="outlined" (click)="onDeleteClicked()">Delete</mc-button>
    }
    @if(workOrder?.maintenanceOrderStatusCode==340 && authPolicy.canMarkWorkOrderAsTTL){
    <mc-button data-cy="mark-ttl" fit="medium" [loading]="markAsCompleteAPICallStatus==='IN_PROGRESS'" (click)="onMarkTTLClicked()">Mark as
        TTL</mc-button>
    }
</div>
}

@if(viewDataApiCalls==="IN_PROGRESS"){
<div class="aems-card m-t-36">
    <div class="pure-g">
        @for(x of "1234567890";track $index){
        <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <app-loader height="40px"></app-loader>
        </div>
        }
    </div>
</div>
<div class="aems-card m-t-12">
    <p class="mds-content"><strong>Container Details</strong></p>
    <hr class="m-b-12" />
    <div class="pure-g">
        @for(x of "123456789012345";track $index){
        <div class="pure-u-md-8-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <app-loader height="40px"></app-loader>
        </div>
        }
    </div>
</div>
<div class="aems-card m-t-12">
    <div class="pure-g">
        @for(x of "123456789";track $index){
        @for(x of "12345678";track $index){
        <div class="pure-u-md-3-24 pure-u-lg-3-24 p-b-16 p-r-8">
            <app-loader></app-loader>
        </div>
        }
        }
    </div>
</div>
}
<mc-modal hiddenclose backdropcloseactiondisabled heading="Delete work order" fit="small" dimension="small"
    [open]="openDeleteWorkOrderConfirmation" (closed)="onDeleteWorkOrderConfirmationClosed()">
    @if(deleteApiCallStatus==="NOT_STARTED"){
    <p style="font-size: 0.8rem;">The workorder will be deleted permanently. Are you sure you want to delete?</p>
    }
    @if(deleteApiCallStatus==="IN_PROGRESS"){
    <p style="font-size: 0.8rem;">Deleting workorder...</p>
    }
    @if(deleteApiCallStatus==="COMPLETED_SUCCESFULLY"){
    <p>Workorder deleted !</p>
    <p>Taking you back to list page..</p>
    }
    @if(deleteApiCallStatus!=='COMPLETED_SUCCESFULLY'){
    @if(deleteApiCallStatus!=='IN_PROGRESS'){
    <mc-button slot="secondaryAction" dialogaction="cancel" appearance="neutral" variant="outlined">Cancel</mc-button>
    }
    <mc-button slot="primaryAction" appearance="primary" [loading]="deleteApiCallStatus==='IN_PROGRESS'"
        (click)="onDeleteWorkOrderConfirmed()">Yes</mc-button>
    }
</mc-modal>
@if(workOrder)
{
    <mc-modal backdropcloseactiondisabled heading="Audit Log" fit="medium" [open]="openAuditLog"
    (closed)="onAuditLogClosed()">
        <app-audit-log [workOrder]="workOrder" [workOrderId]="id"></app-audit-log>
    </mc-modal>
}
<mc-modal heading="Mark As TTL" fit="small" dimension="small" [open]="openMarkAsTTLConfirmation"
    (closed)="onMarkAsTTLConfirmationClosed()">
    <p style="font-size: 0.8rem;">Are you sure to mark it as total loss?</p>
    <mc-button slot="secondaryAction" dialogaction="cancel" appearance="neutral" variant="outlined"
        (click)="onMarkAsTTLConfirmationCancel()">Cancel</mc-button>
    <mc-button slot="primaryAction" appearance="primary" (click)="onMarkAsTTLConfirmationDone()">Yes</mc-button>
</mc-modal>