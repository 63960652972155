<div class="table-container m-t-12">
    @if((getWorkOrdersApiCallStatus==="COMPLETED_SUCCESFULLY" && data.length>0) || getWorkOrdersApiCallStatus ==="IN_PROGRESS" ){ 
        <mc-table data-cy="work-order-list" [data]="getWorkOrdersApiCallStatus==='COMPLETED_SUCCESFULLY' && data.length>0 ? data : mockDataForLoadingAnimation" fit="small" [columns]="columns" nowrap="true" sortmanual
            sortdefaultdirection="ascending" (sortchange)='onSortDirectionChange($event)' tabularFigures="true"
            headersticky height="100%" >
            <ng-container *ngFor="let row of data; let i = index">
                <div slot="{{ row.id }}_maintenanceOrderNumber">
                    <a [routerLink]="['/workorders', row.maintenanceOrderNumber]">{{row.maintenanceOrderNumber}}</a>
                </div>
            </ng-container>
            @for(row of mockDataForLoadingAnimation;track $index){
                        @for (item of columns; track $index) {
                            <div slot="{{row.id}}_{{item.id}}">
                                <app-loader></app-loader>
                            </div>
                        }
            }
        </mc-table>
    }
    @else{
        <div class="pure-g">
            <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-204 flex-container flex-center no-data">
                <span i18n>No Data Found</span>
            </div>
            <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-36 flex-container flex-center">
                <span i18n>To widen your search, change or remove filters.</span>
            </div>
            <div class="pure-u-md-24-24 pure-u-lg-24-24 pure-u-xl-24-24 m-t-36 flex-container flex-center">
                <mc-button i18n-label="Button" label="Clear all filters" variant="filled" appearance="primary"
                    (click)="clearFilter()" fit="small"></mc-button>
            </div>
        </div>
    }
</div>
@if(data.length>0){
<div class="pure-g m-t-16 footer">
    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-4-24">
        <mc-select style="width:140px;display: block;" [value]="pageLimit" optionswidth="100px" ngDefaultControl label="Per page" labelposition="left"
            fit="small" (optionselected)="onPageLimitChange($event)">
            @for (pageCount of [10,20,50,100,200]; track $index) {
            <mc-option [value]="pageCount">{{pageCount}}</mc-option>
            }
        </mc-select>
    </div>

    <div class="pure-u-md-12-24 pure-u-lg-16-24 pure-u-xl-16-24 flex-container flex-center">
        @if(data.length!=totalItemCount){
        <mc-pagination hiddenlabels [currentpage]="currentPage" [totalpages]="lastPage" visiblepages="7"
            (pagechange)="onPageChange($event)" fit="small">
        </mc-pagination>
    }
    </div>

    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-4-24 text-align-right">
        <span class="page-description" i18n>Showing {{data.length}} of
            {{totalItemCount}}</span>
    </div>
</div>
}
