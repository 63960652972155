import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { McTable } from '@maersk-global/mds-components-core';
import { getTPIChar } from '../../../models/tpi.model';
import { utils } from '../../../helper/utils';
import { MaintenanceOrderLineItem } from '../../../models/maintenance-order-line-item';
import { AemsFile } from '../../../models/aems-file.model';
import { FileUploadComponent } from '../../aems-file-upload/file-upload.component';
import { ListRepairPartItemsComponent } from '../list-repair-part-items/list-repair-part-items/list-repair-part-items.component';
import { MaintenanceOrderPart } from '../../../models/maintenance-order-part';
import { TagInputComponent } from '../../tag-input/tag-input.component';
import { MaintenanceOrderStatusCode } from '../../../models/maintenance-order-status.model';

type part={
  partNumber:string;
  partName:string;
  unitPrice:number;
  importTaxApplicable:string;
 unitQuantity:number;
 currentSerialNumbers?:string[];
 newSerialNumbers?:string[];
}
type repairLineItem={
  id:string;
  damageCode:string;
  repairCode:string;
  description:string;
  repairLocationCode:string;
  tpiCode:string;
  pcs:number;
  manHoursPerPiece:number;
  materialCostPerPiece:number;
  itemTotal:number;
  parts?:MaintenanceOrderPart[];
  files:AemsFile[]
}
@Component({
  selector: 'app-list-repair-line-items',
  standalone: true,
  imports: [FileUploadComponent,ListRepairPartItemsComponent],
  templateUrl: './list-repair-line-items.component.html',
  styleUrl: './list-repair-line-items.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ListRepairLineItemsComponent implements OnInit {
  ngOnInit(): void {
    this.mapData();
    console.log("Data Type"+ typeof(this.data));
  }
  @Input({ required: true }) data: MaintenanceOrderLineItem[] = [];
  @Input({ required: true }) maintainceOrderStatus?: MaintenanceOrderStatusCode;
  @Input({ required: true }) shopCurrency!: string;
  tableData:repairLineItem[]=[];
  repairLineItemTableColumns: Array<TableColumn> = [
    {
      id: 'damageCode',
      label: 'Damage Code',
      sortDisabled:true
    },
    {
      id: 'repairCode',
      label: 'Repair Code',
      sortDisabled:true
    },
    {
      id: 'description',
      label: 'Description',
      sortDisabled:true
    },
    {
      id: 'repairLocationCode',
      label: 'Repair Loc Code',
      sortDisabled:true
    }, {
      id: 'tpiCode',
      label: 'TPI Code',
      sortDisabled:true
    }, {
      id: 'pcs',
      label: 'Pcs',
      sortDisabled:true
    }, {
      id: 'manHoursPerPiece',
      label: 'Man Hours /Pc',
      sortDisabled:true,
      align:"right"
    }, {
      id: 'materialCostPerPiece',
      label: 'Material Cost/pc',
      sortDisabled:true,
      align:"right"

    }, {
      id: 'itemTotal',
      label: 'Item Total',
      sortDisabled:true,
      align:"right"
    },
    {
      id:'attachments',
      label:'',
      sortDisabled:true
    }
  ]
  partsTableColumn:Array<TableColumn>=[
    {
      id: 'maintenanceOrderPartNumber',
      label: 'Part Number',
      sortDisabled:true
    },
    {
      id: 'maintenanceOrderPartDescription',
      label: 'Part Description',
      sortDisabled:true
    },
    {
      id: 'maintenanceOrderPartCost',
      label: 'Part Cost',
      sortDisabled:true
    },
    {
      id: 'currentSerialNumbers',
      label: 'Old Part Serial Number',
      sortDisabled:true
    }
  ]
 
  mapData(){
    
    this.tableData= this.data.map(item=>{
      return {
        id:item.maintenanceTypeCode+item.maintenanceRepairLocationCode,
        damageCode:item.equipmentDamageCode,
        repairCode:item.maintenanceTypeCode,
        description:item.maintenanceTypeName ?? 'Not Available',
        repairLocationCode:item.maintenanceRepairLocationCode,
        tpiCode:getTPIChar(item.thirdPartyIndicator),
        pcs:item.repairQuantity,
        manHoursPerPiece:item.manHoursPerPiece,
        materialCostPerPiece:utils.formatNumberToTwoDigitDecimal(item.materialCostPerPiece),
        files:item.blobResolvedFiles??[],
        itemTotal:utils.formatNumberToTwoDigitDecimal((item.repairQuantity*item.materialCostPerPiece)+item.maintenanceOrderParts.reduce((accumulator, currentPart) => accumulator + (currentPart.unitPrice*currentPart.unitQuantity), 0)),
        parts:item.maintenanceOrderParts
      }
    })
  }
}
