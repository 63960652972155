<div style="display: inline-flex;gap:1px;">
    <mc-button class="main-button" [label]="label()" [icon]="icon()" [fit]="fit()" [appearance]="appearance()" [variant]="variant()"
        [hiddenlabel]="hiddenlabel()" [routerLink]="parentButtonRouterLink()">
    </mc-button>
    <mc-menu arrow position="bottom-right" [zindex]="999999">
        <mc-button class="menu-button" [fit]="fit()" [appearance]="appearance()" [variant]="variant()" slot="trigger" hiddenlabel
            label="menu" icon="chevron-down"></mc-button>
       
        <mc-list>
            @for (menu of menuItems(); track $index) {
                <mc-list-item [routerLink]="menu.routerLink">{{menu.label}}</mc-list-item>
            }
        </mc-list>
    </mc-menu>
</div>