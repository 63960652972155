<form [formGroup]="workOrderSearchTextForm">
    <div>
        <mc-input hiddenlabel placeholder="Search for a Work Order or Equipment Number" fit="small" ngDefaultControl
            formControlName="searchText" (keyup.enter)="applySearchTextFilters()" icon="magnifying-glass"
            [clearbutton]=true (clearbuttonclick)="applySearchTextFilters()"
            [attr.invalid]="isControlInErrorState(this.workOrderSearchTextForm.controls.searchText)">
            @if(this.workOrderSearchTextForm.controls.searchText.hasError('pattern')){
            <span slot="errormessage">Incorrect work order or equipment number</span>
            }
        </mc-input>
    </div>
</form>