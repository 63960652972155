; import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { WorkOrderSearchTextForm } from '../../../form-model/work-order-search-text.form.model';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SearchComponent {
  @Input({ required: true }) workOrderSearchTextForm!: FormGroup<WorkOrderSearchTextForm>;

  applySearchTextFilters() {
    this.workOrderSearchTextForm.controls.appliedWOSearchTextFilter.setValue(true);
  }
  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.addControlsToTheFormGroup()
  }

  isControlInErrorState(control: FormControl): boolean | null {
    if (control.invalid && control.touched) {
      return true;
    }
    return null;
  }

  private addControlsToTheFormGroup() {
    const validTextSearchPattern = /^([A-Z]{4}[0-9]{6,9}|\d+)$/;
    this.workOrderSearchTextForm.addControl('searchText', this.formBuilder.control<string | null>(null, {
      validators: [Validators.pattern(validTextSearchPattern)]
    }));
    this.workOrderSearchTextForm.addControl('appliedWOSearchTextFilter', this.formBuilder.control<boolean | null>(null));
  }
}
