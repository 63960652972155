import { HttpErrorResponse, HttpInterceptorFn} from '@angular/common/http';
import {Observable, catchError, delay, from, switchMap, throwError } from 'rxjs';
import { AuthHelper } from '../auth/auth-helper';

export const http401Interceptor: HttpInterceptorFn = (req, next) => {
  return next(req).pipe(
    catchError((error: HttpErrorResponse,caught:Observable<any>) => {
      if(error.status===401){
        return from(AuthHelper.refreshAccessToken()).pipe(
          delay(1000),
          switchMap(()=>{ 
            const updatedReq=req.clone({
              setHeaders:{
                Authorization:`Bearer ${AuthHelper.accessToken}`
              }
            });
            return next(updatedReq);
          })
        )
      }else{
      return throwError(()=>error);
      }
    })
  )
};
