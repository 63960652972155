// telemetry.service.ts
import { Injectable } from '@angular/core';
import { LogLevel } from "@grafana/faro-web-sdk";
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import { environment } from '../../environments/environment';
import { AuthHelper } from '../auth/auth-helper';
@Injectable({
  providedIn: 'root',
})
export class TelemetryService {
  private faro: any;

  constructor() {
    this.initializeFaro();
  }

  private initializeFaro() {

    this.faro = RumInit({
      app: {
        name: environment.MopRumAppName,
        version: '1.0.0',
        environment: environment.ENVIRONMENT,
      },
      apiKey: environment.MopRumApiKey,
      useSendBeacon: true,
      sso_user: true,
      ignoreUrls: ['.*localhost.*', '.*pensieve.*'],
      instrumentations: {
        interactions: false,
        console: {
          disabledLevels: [LogLevel.TRACE, LogLevel.DEBUG, LogLevel.LOG],
        },
      },
      debug: false,
      traceLog: false,
      propagateTraceHeaderCorsUrls: [new RegExp('.*api.*')],
    
  });

    const user = AuthHelper.user;
    this.faro.api.setUser({
      id: user?.userId.toString(),
      email: user?.emailAddress,
      username: user?.username,
    });
    /*// Capture and set screen size after initialization
    const screenSize = `${window.screen.width}x${window.screen.height}`;
    this.faro.api.setGlobalAttributes({
      screen_size: screenSize,  // Set screen size as a global attribute for all events
    });*/
  }
  public logScreenSize() {
    const screenSize = `${window.screen.width}x${window.screen.height}`;
    this.faro.api.pushLog(['screen-size:', screenSize], {
      level: LogLevel.INFO,
    });
  }

  public logFileEvent(eventType: string, fileCount: number) {
    this.faro.api.pushLog(['file-event:', eventType], {
      level: LogLevel.INFO,
      fileCount,
    });
  }
  }
  
