<div class="m-t-12">
  <form [formGroup]="repairPartForm">

    <div class="pure-g">
      <div class="pure-u-6-24" style="display:flex">
        <div class="repair-part-label" style="width:100px">Part Number</div>
        <div> {{repairPart?.partNumber}}</div>
      </div>
      <div class="pure-u-12-24" style="display:flex">
        <div class="repair-part-label" style="width:120px">Part Description</div>
        <div> {{repairPart?.partName}}</div>
      </div>
      <div class="pure-u-5-24" style="display:flex">
        <div class="repair-part-label" style="width:70px">Part Cost</div>
        <div> {{repairPart?.unitPrice}}</div>
      </div>
      <div class="pure-u-1-24" style="display:flex">
        <div class="repair-part-label" style="width:40px">Pcs</div>
        <div>{{repairPart?.unitQuantity}}</div>
      </div>
    </div>

    <div class="pure-g m-t-12">
      <div class="pure-u-12-24" style="display:flex;align-items: center;">
        <div class="repair-part-label" style="width:160px">Old Part Serial Number</div>
        <app-tag-input [readOnly]="true" [hiddenLabel]="true" [inputTags]="repairPart?.currentSerialNumbers??[]"
          (values)="onTagChanged('currentSerialNumbers',$event)">
        </app-tag-input>
      </div>
      <div class="pure-u-12-24" style="display: flex;align-items: center;">
        <div class="repair-part-label" style="width:172px">New Part Serial Number</div>
        <app-tag-input style="flex-grow: 1;" [hiddenLabel]="true" [readOnly]="!isSerialNumberEditable()"
          [inputTags]="repairPart?.newSerialNumbers??[]" (values)="onTagChanged('newSerialNumbers',$event)">
        </app-tag-input>
      </div>
    </div>
  </form>
</div>