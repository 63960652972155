<form [formGroup]="repairLineItem">
  <div class="pure-g line-item">
    <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
      <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px" optionswidth="200px"
        ngDefaultControl label="Damage Code" placeholder="Type Code" fit="small" formControlName="damageCode"
        [data]="damageCodesTypeahead" [attr.invalid]="isControlInErrorState(repairLineItem.get('damageCode'))"
        oninput="this.value = this.value.toUpperCase()" [id]="controlId()+'-damage-code'">
        @if(repairLineItem.get('damageCode')?.hasError('required')){
        <span slot="errormessage">Required</span>
        }
        @if(repairLineItem.get('damageCode')?.hasError('typeaheadCodeExistValidator'))
        {
        <span slot="errormessage">Invalid Code</span>
        }
      </mc-typeahead>
    </div>
    <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
      <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px" optionswidth="300px"
        ngDefaultControl label="Repair Code" placeholder="Type Code" fit="small" formControlName="repairCode"
        [data]="repairCodesTypeahead" [attr.invalid]="
            isControlInErrorState(repairLineItem.get('repairCode'))" (blur)="onRepairCodeOptionSelected($event)">
        @if(repairLineItem.get('repairCode')?.hasError('required')){
        <span slot="errormessage">Required</span>
        }
        @if(repairLineItem.get('repairCode')?.hasError('typeaheadCodeExistValidator'))
        {
        <span slot="errormessage">Invalid Code</span>
        }
        @if(repairLineItem.get('repairCode')?.hasError('containerGradeValidator'))
        {
        <span slot="errormessage">Repair code not applicable for mode and the current container grade</span>
        }
        @if(repairLineItem.get('repairCode')?.hasError('manufacturerValidator'))
        {
        <span slot="errormessage">Repair code not applicable for
          {{repairLineItem.get('repairCode')?.getError('manufacturerValidator').value}}</span>
        }
        @if(repairLineItem.get('repairCode')?.hasError('repairSuspendedValidator'))
        {
        <span slot="errormessage">Repair code Suspended</span>
        }
        @if(repairLineItem.get('repairCode')?.hasError('ptiStatusValidator'))
        {
        <span slot="errormessage">Only visual PTI allowed</span>
        }
      </mc-typeahead>
    </div>
    @if(isDamageAndRepairCodeValid()){
    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
      <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px" optionswidth="300px"
        ngDefaultControl label="Repair Loc code" placeholder="Type Code" fit="small"
        formControlName="repairLocationCode" [data]="repairLocationCodesTypeahead" [attr.invalid]="
            isControlInErrorState(repairLineItem.get('repairLocationCode'))"
        oninput="this.value = this.value.toUpperCase()">
        @if(repairLineItem.get('repairLocationCode')?.hasError('required')){
        <span slot="errormessage">Required</span>
        }
        @if(repairLineItem.get('repairLocationCode')?.hasError('typeaheadCodeExistValidator'))
        {
        <span slot="errormessage">Invalid Code</span>
        }
      </mc-typeahead>
    </div>
    <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-2-24 p-r-8 m-b-12">
      <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px" optionswidth="400px"
        ngDefaultControl label="TPI" placeholder="Type Code" fit="small" formControlName="tpiCode"
        [data]="tpiCodesTypeahead" [attr.invalid]="isControlInErrorState(repairLineItem.get('tpiCode'))"
        oninput="this.value = this.value.toUpperCase()">
        @if(repairLineItem.get('tpiCode')?.hasError('required')){
        <span slot="errormessage">Required</span>
        }
        @if(repairLineItem.get('tpiCode')?.hasError('typeaheadCodeExistValidator'))
        {
        <span slot="errormessage">Invalid Code</span>
        }
      </mc-typeahead>
    </div>
    <div class="pure-u-md-2-24 pure-u-lg-2-24 pure-u-xl-1-24 p-r-8 m-b-12">
      <mc-input ngDefaultControl label="Pcs" fit="small" formControlName="pieces"
        [attr.invalid]="isControlInErrorState(repairLineItem.get('pieces'))">
        @if(repairLineItem.get('pieces')?.hasError('required')){
        <span slot="errormessage">Required</span>
        } @if(repairLineItem.get('pieces')?.hasError('max')) {
        <span slot="errormessage">Invalid</span>
        }@if(repairLineItem.get('pieces')?.hasError('pattern')){
        <span slot="errormessage">Incorrect Format</span>
        }
      </mc-input>
    </div>
    <div class="pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-2-24 p-r-8 m-b-12">
      <mc-input ngDefaultControl label="Man Hrs/pc" fit="small" formControlName="manHoursPerPiece"
        [attr.invalid]="
            isControlInErrorState(repairLineItem.get('manHoursPerPiece'))">
             @if(repairLineItem.get('manHoursPerPiece')?.hasError('required')){
              <span slot="errormessage">Required</span>
              } @if(repairLineItem.get('manHoursPerPiece')?.hasError('max')) {
              <span slot="errormessage">Exceeds maximum allowed limit</span>
              }@if(repairLineItem.get('manHoursPerPiece')?.hasError('pattern')){
              <span slot="errormessage">Incorrect Format</span>
              }
      </mc-input>
    </div>
    <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
      <mc-input ngDefaultControl label="Material cost/pc" fit="small" formControlName="materialCostPerPiece"
        [suffix]="shop().maintenanceShopCurrency.currencyCode " [attr.invalid]="
            isControlInErrorState(repairLineItem.get('materialCostPerPiece'))
          ">
        @if(repairLineItem.get('materialCostPerPiece')?.hasError('required')){
        <span slot="errormessage">Required</span>
        } @if(repairLineItem.get('materialCostPerPiece')?.hasError('max')) {
        <span slot="errormessage">Exceeds maximum allowed limit</span>
        }@if(repairLineItem.get('materialCostPerPiece')?.hasError('pattern')){
        <span slot="errormessage">Incorrect Format</span>
        }
      </mc-input>
    </div>
    <div class="pure-u-md-10-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
      <mc-input ngDefaultControl label="Item Total" fit="small" formControlName="itemTotalCost"
        [suffix]="shop().maintenanceShopCurrency.currencyCode" readonly>
      </mc-input>
    </div>
    <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
      <app-file-upload [inputFiles]="existingRepairLineItem?.blobResolvedFiles??[]"
        [parentForm]="repairLineItem.controls.attachments" labelForCompact="Images"
        [shopCode]="shop().maintenanceShopCode" type="compact" labelForCompact="Images">
      </app-file-upload>
    </div>
    }
    <div style="text-align: right;" class="pure-u-md-4-24 pure-u-lg-1-24 pure-u-xl-1-24 p-r-8 m-b-12 m-t-20">
      <mc-button class="m-r-4 m-t-4 repair-line-item-delete-button" label="Button " variant="outlined"
        appearance="neutral" fit="small" icon="trash" hiddenlabel padding="default"
        (click)="onRemoveRepairLineItemClicked()">
      </mc-button>
    </div>
    @if(loadingParts() && (isPartsMandatory || repairHasExistingParts()) ){
    <div class="pure-u-24" style="width:100%">
      <div class="pure-g" style="width:65%">
        @for (n of [1,2,3,4]; track $index) {
        <div class="pure-u-md-8-24 pure-u-lg-6-24 pure-u-xl-6-24 p-r-8 m-b-12">
          <ngx-skeleton-loader [theme]="{ height: '30px' }" count="1" appearance="line"></ngx-skeleton-loader>
        </div>
        }
      </div>
    </div>
    }
    @if(parts.length>0){
    @if(isPartsMandatory || repairHasExistingParts()){
    <app-repair-parts [parentForm]="repairLineItem" [isPartsMandatory]="isPartsMandatory"
      [availablePartsForSelectedRepairCodeAndMode]="parts" [shop]="shop()"
      [existingParts]="existingRepairLineItem?.maintenanceOrderParts"
      [partsSnapshotData]="partsSnapshotData"
      (repairPartsTotal)="onRepairPartsTotalChanged($event)"
      (allPartsRemoved)="allPartsRemoved()"></app-repair-parts>
    }
    @else{
    @if(!showOptionalParts){
    <mc-button (click)="addOptionalParts()" label="Add Parts" fit="small"></mc-button>
    }
    @else{
    <app-repair-parts [parentForm]="repairLineItem" [isPartsMandatory]="isPartsMandatory"
      [availablePartsForSelectedRepairCodeAndMode]="parts" [shop]="shop()"
      [existingParts]="existingRepairLineItem?.maintenanceOrderParts" 
      [partsSnapshotData]="partsSnapshotData"
      (repairPartsTotal)="onRepairPartsTotalChanged($event)"
      (allPartsRemoved)="allPartsRemoved()"></app-repair-parts>
    }
    }
    }
  </div>
</form>