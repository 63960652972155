import { Component } from '@angular/core';
import { BulkWorkOrderHeaderEquipmentsComponent } from '../../components/bulk-work-order/bulk-work-order-header/bulk-work-order-header.component';
import { BulkWorkOrderRepairLineItemsComponent } from '../../components/bulk-work-order/bulk-work-order-repair-line-items/bulk-work-order-repair-line-items.component';
import { BulkWorkOrderManhoursComponent } from '../../components/bulk-work-order/bulk-work-order-manhours/bulk-work-order-manhours.component';
import { BulkWorkOrderRemarkComponent } from '../../components/bulk-work-order/bulk-work-order-remark/bulk-work-order-remark.component';

@Component({
  selector: 'app-bulk-work-order',
  standalone: true,
  imports: [BulkWorkOrderHeaderEquipmentsComponent,
    BulkWorkOrderRepairLineItemsComponent,
    BulkWorkOrderManhoursComponent,
    BulkWorkOrderRemarkComponent],
  templateUrl: './bulk-work-order.component.html',
  styleUrl: './bulk-work-order.component.scss'
})
export class BulkWorkOrderComponent {
  headerValueChanged(value: any) {
    console.log(value);
  }
}
