import { ValidatorFn, AbstractControl } from "@angular/forms";
import { Repair } from "../../../../models/repair.model";

export function repairSuspendedValidator(
    repairs : Repair[]
  ): ValidatorFn {
    return (control : AbstractControl) => {
      let enteredValue=control.value as string;
      if(!enteredValue){
        return null;
      }
      let selectedRepair = repairs.find(x=>x.code == enteredValue);
      if(selectedRepair){
      return selectedRepair.isSuspended == false ?  null :{ repairSuspendedValidator: {value : control.value} };
      }
      else{
        return null;
      }
  }
}