import { CodeDescription } from "./code-description.model";

export interface ThirdPartyIndicator extends CodeDescription{
    displayText: string;
    name: string;
}

export function getTPIChar (indicator:string){
    switch(indicator){
        case "INTERMODAL": return "V"
        case "OWNER": return "O"
        case "TERMINAL": return "S"
        case "THIRD_PARTY": return "T"
        case "WEAR_AND_TEAR": return "W"
        case "MANUFACTURE_WARRANTY": return "M"
        default: return "V"
    }
}