@if(totalCostDetails()){
<p class="font-style m-b-16">Exchange Rate {{totalCostDetails().shopExchangeRateToUsd}}</p>
<div class="m-b-12">
    <p class="font-style">Material</p>
    <mc-table
        [data]="materialCostTotal()"
        [columns]="materialCost"
        fit="small"
        sortdisabled="true">
    </mc-table>
</div>
<div class="m-b-12">
    <p class="font-style">Labor</p>
    <p class="m-b-4">Total Hours Including Preparation Time - {{totalHours}} hrs</p>
    <mc-table
        [data]="laborCostTotal()"
        [columns]="laborCost"
        fit="small"
        sortdisabled="true">
        <ng-container *ngFor="let row of laborCostTotal(); let i = index">
            <div slot="{{ row.id }}_category" class="font-style">
                {{row.category}}
            </div>
            <div slot="{{ row.id }}_laborCostUSD" class="font-style">
                {{row.laborCostUSD}}
            </div>
            <div slot="{{ row.id }}_laborCostLocal" class="font-style">
                {{row.laborCostLocal}}
            </div>
        </ng-container>
    </mc-table>
</div>
@if(hasParts()){
    <div class="m-b-12">
        <p class="font-style">Parts</p>
        <mc-table
            [data]="partCostTotal()"
            [columns]="partCost"
            fit="small"
            sortdisabled="true">
            <ng-container *ngFor="let row of partCostTotal(); let i = index">
                <div slot="{{ row.id }}_partNumber" class="font-style">
                    {{row.partNumber}}
                </div>
                <div slot="{{ row.id }}_total" class="font-style">
                    {{row.total}}
                </div>
            </ng-container>
        </mc-table>
    </div>
}
<div class="flex-cont-with-gap">
    <div>
        <p class="font-style">Tax</p>
        <mc-table
                [data]="taxTotal()"
                [columns]="tax"
                fit="small"
                sortdisabled="true">
        </mc-table>
    </div>
    <div>
        <p class="font-style">Total</p>
        <mc-table
            [data]="totalCost()"
            [columns]="total"
            fit="small"
            sortdisabled="true">
        </mc-table> 
    </div> 
</div>
}