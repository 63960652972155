import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, signal, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  FormBuilder,
  ReactiveFormsModule,
  FormControlStatus,
} from '@angular/forms';
import { ErrorComponent } from '../../shared/error/error.component';
import { CreateWorkOrderHeaderComponent }
  from "../../components/create-work-order/header/cwo-header.component";
import { CreateWorkOrerContainerDetailsComponent }
  from "../../components/create-work-order/container-details/cwo-container-details.component";
import { CreateWorkOrderAttachmentsComponent }
  from '../../components/create-work-order/attachments/cwo-attachments.component';
import { CreateWorkOrderLineItemsComponent }
  from '../../components/create-work-order/line-items/cwo-line-items.component';
import { CreateWorkOrderRemarksComponent }
  from '../../components/create-work-order/remarks/cwo-remarks.component';
import '@maersk-global/mds-components-core/mc-button';
import { CreateWorkOrderAlarmDetailsComponent }
  from '../../components/create-work-order/cwo-alarm-details/cwo-alarm-details.component';
import { WorkOrderService } from '../../services/work-order.service';
import { Mode } from '../../models/mode.model';
import { Shop } from '../../models/shop.model';
import { Equipment } from '../../models/equipment.model';
import { HeaderForm } from '../../form-model/cwo-header.form.model';
import { catchError, combineLatest, debounceTime, distinctUntilChanged, EmptyError, forkJoin, ReplaySubject, Subscription, takeUntil, timer } from 'rxjs';
import { CodeDescription } from '../../models/code-description.model';
import { ContainerGrade } from '../../models/container-grade.model';
import { WarningComponent } from '../../shared/warning/warning.component';
import { WarningSharedService } from '../../components/create-work-order/warning-shared-service';
import { RepairLineItemsForm } from '../../form-model/damage-and-repair.form.model';
import { RemarkForm } from '../../form-model/cwo-remark.form.model';
import { CreateWorkOrderManHoursComponent } from '../../components/create-work-order/man-hours/cwo-man-hours/cwo-man-hours.component';
import { ManHoursForm } from '../../form-model/cwo-man-hours.form.model';
import { CreateWorkOrderTotalCostComponent } from '../../components/create-work-order/total-cost/cwo-total-cost/cwo-total-cost.component';
import { ToastrService } from '../../services/toastr-service';
import { utils } from '../../helper/utils';
import { AttachmentForm } from '../../form-model/attachment.form.model';
import { WorkOrderSubmitErrorModel } from '../../models/workorder-submit-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TotalCostForm } from '../../form-model/total-cost.form.model';
import { ThirdPartyIndicator } from '../../models/tpi.model';
import { MaintenanceOrderResponse } from '../../models/maintenance-order-response.model';
import { TotalCost } from '../../models/total-cost-model';
import { RepairLocation } from '../../models/repair-location.model';
import { Repair } from '../../models/repair.model';
import { PrincipalProperty } from '../../models/principal-property.model';
import { clone } from 'lodash-es';
import { EditWorkOrderHeaderComponent } from '../../components/edit-work-order/header/edit-work-order-header/edit-work-order-header.component';
import { SharedDataService } from '../../services/shared-data.service';
import { APICallStatus } from '../../models/api-call-status.mode';
import { Currency } from '../../models/currency.model';
import { RepairExclusion } from '../../models/repair-exclusion.model';
import { RepairLineItemsDataService } from './repair-line-items-data.service';
import { CreateWorkOrderFormToRequestMapperService } from './create-work-order-form-to-request-mapper.service';
import { MasterDataService } from '../../services/master-data.service';
import { ShopRepairCostAllocation } from '../../models/shop-repair-cost-allocation.model';

@Component({
  selector: 'app-create-work-order',
  standalone: true,
  imports: [
    RouterModule,
    ReactiveFormsModule,
    CreateWorkOrderHeaderComponent,
    CreateWorkOrerContainerDetailsComponent,
    CreateWorkOrderAttachmentsComponent,
    CreateWorkOrderLineItemsComponent,
    CreateWorkOrderManHoursComponent,
    CreateWorkOrderRemarksComponent,
    CreateWorkOrderAlarmDetailsComponent,
    CreateWorkOrderTotalCostComponent,
    WarningComponent,
    CommonModule,
    ErrorComponent,
    NgxSkeletonLoaderModule,
    EditWorkOrderHeaderComponent,
  ],
  templateUrl: './create-work-order.component.html',
  styleUrl: './create-work-order.component.scss',
  providers: [RepairLineItemsDataService, CreateWorkOrderFormToRequestMapperService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateWorkOrderComponent implements OnInit {
  @Input() workorderId: string | undefined = undefined;

  // Properties for this component
  formSubmitErrors = signal<string[]>([]);
  headerFormValid = signal<boolean>(false);
  submitLoader: boolean = false;
  warnings = this.warningSharedService.getWarnings();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  openCancelConfirmation: boolean = false;
  isEditMode = signal(false);
  workorder: MaintenanceOrderResponse | undefined;
  workOrderCurrency!: Currency;
  calculateTotalApiCallStatus: APICallStatus = "NOT_STARTED";

  // Properties for man hours component
  totalManhoursWithoutPrepTime = 0;
  manHoursThatQualifyForPrepTime = 0;
  isPrepTimeApplicable = false;


  // Properties coming from header and must for the work order
  mode!: Mode;
  shop!: Shop
  equipment!: Equipment;

  // Properties for repair line items components
  repairs: Repair[] = [];
  damages: CodeDescription[] = [];
  repairLocations: RepairLocation[] = [];
  tpiCodes: ThirdPartyIndicator[] = [];
  containerGrades: ContainerGrade[] = [];
  repairExclusions: RepairExclusion[] = [];
  gettingDataFromAPIForRepairLineItemsForm = signal(true);
  repairLineItemsApiCallsSubscription:Subscription | undefined;

  // The parent form of all the forms
  createWorkOrderForm = this.formBuilder.group({
    headerForm: this.formBuilder.group<HeaderForm>({} as HeaderForm),
    attachmentForm: this.formBuilder.group<AttachmentForm>({} as AttachmentForm),
    damageAndRepairForm: this.formBuilder.group<RepairLineItemsForm>({} as RepairLineItemsForm),
    manHoursForm: this.formBuilder.group<ManHoursForm>({} as ManHoursForm),
    remarkForm: this.formBuilder.group<RemarkForm>({} as RemarkForm),
    totalCostForm: this.formBuilder.group<TotalCostForm>({} as TotalCostForm)
  });

  // Properties for total cost component
  cost: TotalCost | null = null;
  repairLineItemsAndManhourFormValid = true;
  shopLimit!: ShopRepairCostAllocation;

  constructor(private workOrderService: WorkOrderService, private formBuilder: FormBuilder,
    private warningSharedService: WarningSharedService,
    private toastr: ToastrService, private router: Router,
    private sharedDataService: SharedDataService,
    private repairLineItemDataService: RepairLineItemsDataService,
    private formToRequestBodyMapper: CreateWorkOrderFormToRequestMapperService,
    private masterDataService: MasterDataService,
    private toastrService: ToastrService) { }
  ngOnInit(): void {
    //this.warningSharedService.clearAllWarnings();

    /**
     * Only thing that effects man hours are the values in damage and repair form
     */
    this.createWorkOrderForm.controls.damageAndRepairForm.valueChanges.subscribe((value) => {
      this.calculateManHoursForRepairLineItems(value);
    });

    /**
     * Cost is effected by both damageAndRepair form and ManHours form 
     */
    combineLatest([
      this.createWorkOrderForm.controls.damageAndRepairForm.valueChanges,
      this.createWorkOrderForm.controls.manHoursForm.valueChanges
    ])
      .pipe(takeUntil(this.destroyed$), distinctUntilChanged(), debounceTime(500))
      .subscribe(() => {
        this.calculateCost();
      });

    /**
     * If its an edit action then we will get workOrderid from the route parameter
     */
    if (this.workorderId) {
      this.sharedDataService.getWorkOrder().subscribe(workorder => {
        if (workorder) {
          this.workorder = workorder;
          this.isEditMode.set(true);
        } else {
          this.router.navigate(['/workorders', this.workorderId])
        }
      });
      this.workOrderService.getWorkOrderOrderExchangeRateInfoById(this.workorderId).subscribe(workorderCurrency => {
        this.workOrderCurrency = workorderCurrency;
      });
    }
  }

  private calculateCost() {
    const reqBody = this.formToRequestBodyMapper.map(this.createWorkOrderForm, this.tpiCodes, this.equipment.equipmentTypeCode)
    if (this.createWorkOrderForm.controls.damageAndRepairForm.valid && this.createWorkOrderForm.controls.manHoursForm.valid && this.createWorkOrderForm.controls.damageAndRepairForm.touched) {
      this.repairLineItemsAndManhourFormValid = true;
      this.calculateTotalApiCallStatus = "IN_PROGRESS";
      this.workOrderService.calculateTotalCost(this.workorderId, reqBody).pipe(catchError(err => {
        this.calculateTotalApiCallStatus = "FAILED";
        throw err
      })).subscribe(cost => {
        this.cost = clone(cost);
        this.calculateTotalApiCallStatus = "COMPLETED_SUCCESFULLY";
        this.compareTotalCostWithShopLimit(this.cost.totalOrderCostWithTax, this.shopLimit);
      })
    }
    else {
      this.repairLineItemsAndManhourFormValid = false;
      this.calculateTotalApiCallStatus = "NOT_STARTED";
    }
  }

  private compareTotalCostWithShopLimit(totalCost: number, shopLimit: ShopRepairCostAllocation) {
    if(shopLimit && shopLimit.maxOrderCost!=0 && totalCost > shopLimit.maxOrderCost){
      this.toastrService.showToastr(`The total cost of the work order is more than the shop material limit set for given mode '${this.mode.code}' and shop code '${this.shop.maintenanceShopCode}'`, 'warning',60000);
    }
  }

  private calculateManHoursForRepairLineItems(value: typeof this.createWorkOrderForm.controls.damageAndRepairForm.value) {
    this.totalManhoursWithoutPrepTime = 0;
    this.manHoursThatQualifyForPrepTime = 0;
    this.isPrepTimeApplicable = false;
    value.repairLineItems?.forEach((repairLineItem) => {
      let manhours = +(repairLineItem?.manHoursPerPiece ?? 0);
      let pieces = +(repairLineItem?.pieces ?? 0);
      this.totalManhoursWithoutPrepTime = this.totalManhoursWithoutPrepTime + (manhours * pieces);
      if (this.shop.isPrepTimeEnabled) {
        let repair = this.repairs.find(x => x.repairCode === repairLineItem?.repairCode)
        if (!repair?.excludePreparationHours) {
          this.manHoursThatQualifyForPrepTime = this.manHoursThatQualifyForPrepTime + (manhours * pieces);
          this.isPrepTimeApplicable = true;
        }
      }
    });
    this.totalManhoursWithoutPrepTime = utils.formatNumberToTwoDigitDecimal(this.totalManhoursWithoutPrepTime);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onSubmitClicked(scrollToElementOnError: HTMLHeadElement) {
    if (this.createWorkOrderForm.valid) {
      this.submitWorkOrder(scrollToElementOnError);
    };
  }

  onCancelClicked() {
    if (this.createWorkOrderForm.dirty) {
      this.openCancelConfirmation = true;
    } else {
      this.router.navigate(['/workorders']);
    }
  }
  onCancelConfirmationClosed() {
    this.openCancelConfirmation = false;
  }
  onCancelRejection() {
    this.openCancelConfirmation = false;
  }
  onCancelConfirmation() {
    this.router.navigate(['/workorders'])
    this.openCancelConfirmation = false;
  }
  private submitWorkOrder(scrollTo: HTMLHeadElement) {
    this.submitLoader = true;
    const reqBody = this.formToRequestBodyMapper.map(this.createWorkOrderForm, this.tpiCodes, this.equipment.equipmentTypeCode);
    reqBody.maintenanceOrderNumber = this.workorderId;
    this.workOrderService.createOrUpdateWorkOrder(reqBody, this.workorderId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: MaintenanceOrderResponse) => {
          if (response) {
            this.createWorkOrderForm.reset();
            this.formSubmitErrors.set([]);
            if (this.workorderId) {
              this.toastr.showToastr("Work order re-submitted successfully!", 'success');
            } else {
              this.toastr.showToastr("Work order created successfully!", 'success');
            }
            this.submitLoader = false;
            this.router.navigate(['/workorders', response.maintenanceOrderNumber])
          }
        },
        error: (error: HttpErrorResponse) => {
          let errors: WorkOrderSubmitErrorModel = error.error;
          this.formSubmitErrors.set(["Error Id: " + errors.correlationIdentifier]);
          if (error.status === 422) {
            errors?.violations?.every(error => this.formSubmitErrors.update(existingErrors => {
              existingErrors.push(error.message);
              return existingErrors
            }));
          } else {
            this.formSubmitErrors.set(["Error Id: " + errors.correlationIdentifier, errors.errorMessage])
          }
          this.submitLoader = false;
          this.toastr.showToastr("Failed to create work order.", 'error');
          scrollTo.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
        }
      });
  }

  modeChanged(event: Mode) {
    this.mode = clone(event);
    this.formSubmitErrors.set([]);
  }

  shopChanged(event: Shop) {
    this.shop = clone(event);
    this.workOrderCurrency = this.shop.maintenanceShopCurrency;
    this.formSubmitErrors.set([]);
  }

  headerFormValidChange(event: FormControlStatus) {
    this.headerFormValid.set(false);
    event === "VALID" ? this.headerFormValid.set(true) : this.headerFormValid.set(false);
  }

  equipmentChanged(event: Equipment) {
    this.equipment = clone(event)
    this.createWorkOrderForm.controls.remarkForm.reset()
  }

  closeWarning() {
    this.warningSharedService.clearAllWarnings();
  }

  
  async principalPropertyChanged(event: PrincipalProperty) {
    this.createWorkOrderForm.controls.remarkForm.reset()
    if (event.HeaderFormStatus === "VALID") {
      this.gettingDataFromAPIForRepairLineItemsForm.set(true);
      this.repairLineItemsApiCallsSubscription && this.repairLineItemsApiCallsSubscription.unsubscribe();
      this.repairLineItemsApiCallsSubscription=forkJoin([
        this.repairLineItemDataService.getRepairs(event.Shop.maintenanceShopCode, event.Mode.code, this.workOrderCurrency),
        this.repairLineItemDataService.getRepairExclusions(event.Mode.code),
        this.repairLineItemDataService.getContainerGrades(),
        this.repairLineItemDataService.getDamages(event.Mode.damageType),
        this.repairLineItemDataService.getTPIs(),
        this.repairLineItemDataService.getRepairLocations(),
        this.masterDataService.getShopRepairCostLimitByMode(event.Shop.maintenanceShopCode, event.Mode.code)
      ]).pipe(catchError(err=>{
        this.repairs=[];
        this.repairExclusions=[];
        this.containerGrades=[];
        this.damages=[];
        this.tpiCodes=[];
        this.repairLocations=[];
        throw err
      })).subscribe(([repairs, repairExclusions, containerGrades, damages, tpiCodes, repairLocations,shopRepairCostAlocation]) => {
        const activeRepairs = repairs.filter(repair => repair.isActive && !repair.isSuspended);
        this.repairs = this.repairLineItemDataService.filterRepairCodesByEquipmentManufacturerAndModel(activeRepairs, event.Equipment, event.Mode.damageType)
        this.repairExclusions = repairExclusions;
        this.containerGrades = containerGrades;
        this.damages = damages;
        this.tpiCodes = tpiCodes;
        this.repairLocations = repairLocations.filter(x => x.damageType === event.Mode.damageType);
        this.shopLimit=shopRepairCostAlocation;
        this.gettingDataFromAPIForRepairLineItemsForm.set(false); 
      });
    }
  }
}
