@if(formContainer?.errors){
<div class="error m-b-8">
    <mc-icon class="error-icon" icon="exclamation-octagon" size="24"></mc-icon>
    <div class="title">Errors</div>
    <ul>
    @for(error of getFormErrors(formContainer); track $index){
      <li>{{error}}</li>
    }
    </ul>
</div>
}
