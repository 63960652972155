/// <reference types="@angular/localize" />
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { MdsConfig } from "@maersk-global/mds-config";


// mds icons are dynamically imported from the assets folder
MdsConfig.iconsDynamicImportPath = `${location.protocol}//${location.host}/assets/`;
bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
