<form [formGroup]="filterFormGroup" class="list-work-order-container">
    <div class="pure-g">
        <div class="pure-u-md-10-24 pure-u-lg-14-24">
            <h3 class="inline m-r-4" i18n>Work Orders</h3>
        </div>
        <div class="pure-u-md-14-24 pure-u-lg-10-24 right">
            <app-search class="search-bar"
                [workOrderSearchTextForm]="filterFormGroup.controls.workOrderSearchTextForm"></app-search>
        </div>
    </div>

    <app-filters [filterFormGroup]="filterFormGroup"></app-filters>
    <app-work-order-grid [filterFormGroup]="filterFormGroup" class="table-container"></app-work-order-grid>

</form>