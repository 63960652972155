import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, signal } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Warning } from '../../models/warning';

@Component({
  selector: 'app-warning',
  standalone: true,
  imports: [],
  templateUrl: './warning.component.html',
  styleUrl: './warning.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WarningComponent {
  @Input() warnings: Warning[] | null = [];
  @Output() closeWarning = new EventEmitter();
  onTagActionClick() {
    this.closeWarning.emit();
  }
  getUniqueWarnings() {
    const uniqueMessages = new Set();
    return this.warnings?.filter(warning => {
      if (!uniqueMessages.has(warning.message)) {
        uniqueMessages.add(warning.message);
        return true;
      } return false;
    });
  }
}
