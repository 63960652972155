
<div class="warning-header-form m-b-8 p-b-8 p-t-12 p-l-40">
    <div class="flex-row align-center">
        <div class="flex-row align-center">
          <mc-icon class="warning-icon" icon="exclamation-triangle" size="24"></mc-icon>
          <label>Warnings</label>
        </div>
        <mc-tag style="align-self: flex-end" appearance="warning" fit="small" withaction (click)="onTagActionClick()"></mc-tag>
    </div>
    <ul>
        @for(warning of getUniqueWarnings(); track $index){
            <li>{{warning?.message}}</li>
        }
    </ul>
</div>
