import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { ManHoursForm } from '../../../../../form-model/cwo-man-hours.form.model';
import { utils } from '../../../../../helper/utils';

export function totalHoursSumValidator(): ValidatorFn {
	return (control: AbstractControl) => {
		if (control.invalid) return null;
		let manHoursForm = control as FormGroup<ManHoursForm>;
		let ordinaryHours = +manHoursForm.controls.ordinaryHours.value;
		let totalManHoursOvertime = +manHoursForm.controls.totalManHoursOvertime.value;
		let totalManHoursDoubletime = +manHoursForm.controls.totalManHoursDoubletime.value;
		let totalManHoursMisc = +manHoursForm.controls.totalManHoursMisc.value;
		let totalManHoursWithPrepTime = +manHoursForm.controls.totalManHours.value;
		let sumOfAllHours =
			utils.formatNumberToTwoDigitDecimal(ordinaryHours + totalManHoursOvertime + totalManHoursDoubletime + totalManHoursMisc);
      if (sumOfAllHours !== totalManHoursWithPrepTime) {
			return {
				totalHoursSumValidator: { value: 'The total of Ordinary hours, OT1, OT2 & OT3 must be equal to Total Man Hours' },
			};
		}
		return null;
	};
}
