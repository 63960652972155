import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Equipment } from '../../../../models/equipment.model';



export function equipmentDetailsValidator(): ValidatorFn {
  return (control: AbstractControl):ValidationErrors|null => {
    var equipment=control.value as Equipment;
    if(!equipment)
      return null;
    
      return (validateExpiredLeaseAgreement(equipment,control) || 
              validateLeaseAgreementForSpecificEquipment(equipment,control) || 
              validateInvalidContainerEquipmentTypes(equipment,control)|| 
              null);
      
    
  };
}

/**
 * If ownership type for the equipment is Vessel Sharing Agreement (VSA) or Shipper Owned (SO)
 * then repair is not allowed, irrespective of agreement type.
 * @param equipment 
 * @param control 
 * @returns 
 */
function validateExpiredLeaseAgreement(equipment: Equipment, control: AbstractControl){
  const ownershipTypeCodes: string[] = ['VSA', 'SO'];
  if (ownershipTypeCodes.includes(equipment?.ownershipTypeCode??'')) {
    return { inactiveVSAandSOAgreementValidator: { value: control.value }};
  }
  return null;
}

/**
 * For container equipment types other than 'GENS' and 'CHAS', if the ownership type is Vessel Sharing Agreement (VSA) or Shipper Owned (SO),
 * or if there is lease agreement comment, repair is not allowed.
 * @param equipment 
 * @param control 
 * @returns 
 */
function validateLeaseAgreementForSpecificEquipment(equipment: Equipment, control: AbstractControl) {
  const ownershipTypeCodes: string[] = ['VSA', 'SO'];
  const specificEquipmentTypes: string[] = ["GENS", "CHAS"];

  const isSpecificEquipmentType = specificEquipmentTypes.includes(equipment?.equipmentTypeCode??'');
  const hasLeaseAgreementComment = !!equipment.positionLeaseAgreementComment;
  const hasLeaseAgreementOwnershipType = ownershipTypeCodes.includes(equipment?.ownershipTypeCode??'');

  if (!isSpecificEquipmentType && (hasLeaseAgreementComment || hasLeaseAgreementOwnershipType)) {
    return { equipmentLeaseValidator: { value: control.value } };
  }

  return null;
}

/**
 * If container is of type TWDK/TWFL, then repair is not allowed on this container
 * @param equipment 
 * @param control 
 * @returns 
 */
function validateInvalidContainerEquipmentTypes(equipment: Equipment, control: AbstractControl) {
  const containerParentCode = "CONT";
  const invalidEquipmentTypes = ["TWDK", "TWFL"];

  const isContainerType = equipment.equipmentTypeParentCode === containerParentCode;
  const isInvalidEquipmentType = invalidEquipmentTypes.includes(equipment?.equipmentTypeCode??'');

  if (isContainerType && isInvalidEquipmentType) {
    return { equipmentTypeValidator: { value: control.value } };
  }

  return null;
}

