<div class="aems-card m-b-12">
    <div class="pure-g">
        <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8">
            <div>{{workOrder?.maintenanceOrderNumber}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Work Order</div>
        </div>
        <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8">
            <div>{{workOrder?.vendorsReference??"-"}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Vendor Ref No.</div>
        </div>
        <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8">
            <div>{{workOrder?.equipmentNumber}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Equipment Number</div>
        </div>
        <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8">
            <div>{{workOrderStatusDescription}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Status</div>
        </div>
        <div class="pure-u-md-8-24 pure-u-lg-6-24 p-b-16 p-r-8">
            <div>{{workOrder?.maintenanceRepairModeCode}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Mode</div>
        </div>
    </div>
</div>
<div>
    <div class="pure-g">
        <div class="pure-u-md-5-5">
            <mc-table [data]="auditLogTotal()" [columns]="auditColumns" nowrap="true" sortdisabled fit="small"></mc-table>
        </div>
    </div>
</div>    