import { BehaviorSubject, Observable, of } from "rxjs";
import { MaintenanceOrderResponse } from "../models/maintenance-order-response.model";
import { Injectable } from "@angular/core";
import { Equipment } from "../models/equipment.model";
import { ShopRepairCostAllocation } from "../models/shop-repair-cost-allocation.model";
@Injectable({
    providedIn:'root'
})
export class SharedDataService{
    private workOrder$=new BehaviorSubject<MaintenanceOrderResponse|null>(null)
    setWorkOrder(workOrder:MaintenanceOrderResponse|null){
        this.workOrder$.next(workOrder);
    }
    getWorkOrder():Observable<MaintenanceOrderResponse|null>{
        return this.workOrder$.asObservable();
    }

    private equipment$=new BehaviorSubject<Equipment|null>(null)
    setEquipment(equipment:Equipment|null){
        this.equipment$.next(equipment)
    }
    getEquipment():Observable<Equipment|null>{
        return this.equipment$.asObservable();
    }

    private pastRepairs$=new BehaviorSubject<string[]>([])
    setPastRepairs(pastRepairs:string[]){
        this.pastRepairs$.next(pastRepairs)
    }
    getPastRepairs():Observable<string[]>{
        return this.pastRepairs$.asObservable();
    }
}