import { Component, CUSTOM_ELEMENTS_SCHEMA, input, signal } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Theme } from '../../theme/theme';
import { ThemeService } from '../../theme/theme.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [NgxSkeletonLoaderModule,CommonModule],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss'
})
export class LoaderComponent {
  animation=signal<"progress"|"progress-dark">("progress");
  count=input<number>(1);
  height=input<number|string>(25);
  width=input<number|string>("100%");
  type=input<"line"|"circle">("line");
  constructor(private themeService:ThemeService){
    this.themeService.theme.subscribe(theme=>{
      theme===Theme.DARK?this.animation.set("progress-dark"):this.animation.set("progress")
    })
  }

}
