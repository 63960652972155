import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit, signal } from '@angular/core';
import { MaintenanceOrderResponse } from '../../../models/maintenance-order-response.model';
import { utils } from '../../../helper/utils';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { WorkOrderService } from '../../../services/work-order.service';
import { forkJoin, ReplaySubject, takeUntil } from 'rxjs';
import { MaintenanceOrderChangeResult } from '../../../models/maintenance-order-change-result.model';

interface AuditLogData{
  date:Date;
  user:string;
  description:string;
} 
@Component({
  selector: 'app-audit-log',
  standalone: true,
  imports: [],
  templateUrl: './audit-log.component.html',
  styleUrl: './audit-log.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuditLogComponent implements OnInit{
  @Input() workOrder!: MaintenanceOrderResponse | null;
  @Input() workOrderId!: string;
  workOrderStatus = signal("");
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  auditColumns: Array<TableColumn> = [
    {
      id: 'date',
      label: 'Date',
      dataType: {
        type: 'string',
        formatter: (value) => {
          const date = new Date(value as string);
          return utils.formatDate(date);
        }
      }
    },
    {
      id: 'user',
      label: 'User',
    },
    {
      id: 'description',
      label: 'Description',
    }
  ];
  auditLogTotal = signal<AuditLogData[]>([]);
  auditLog: MaintenanceOrderChangeResult = {} as MaintenanceOrderChangeResult;
  get workOrderStatusDescription(): string {
    return this.workOrder ? utils.getMaintenanceOrderStatusText(this.workOrder.maintenanceOrderStatusCode).description : '';
  }
  constructor(private workOrderService : WorkOrderService ) { }
  ngOnInit(): void {
    this.getAuditLog();
  }
  private getAuditLog() {
    forkJoin([
      this.workOrderService.getAuditLogs(this.workOrderId),
    ]).pipe(takeUntil(this.destroyed$)).subscribe(([auditLog]) => {
      this.auditLog = auditLog;
      this.updateAuditLogColumns();
    });
  }
  updateAuditLogColumns() {
    let auditLogData: AuditLogData[] = [];

    // Map over auditLog changes
    const changesData = this.auditLog.changes?.map((changeResult) => {
    let description = '';

    const oldValue = changeResult.oldValue ? changeResult.oldValue :'EMPTY';
    const newValue = changeResult.newValue ? changeResult.newValue : 'EMPTY';

    // Skip logging if both oldValue and newValue are 'EMPTY' or if they are interchanged null/EMPTY
    if ((oldValue === 'EMPTY' && newValue === 'EMPTY') || (changeResult.oldValue === null && newValue === 'EMPTY') || (oldValue === 'EMPTY' && changeResult.newValue === null)) {
      return null; // Skip this change
    }

    // Check if propertyName refers to lines[n] (e.g., lines[0], lines[1], etc.)
    const linesMatch = changeResult.propertyName.match(/lines\[(\d+)\]\.(.+)/);
    const attachmentsMatch = changeResult.propertyName.match(/attachments\[(\d+)\]\.(.+)/);

    if (linesMatch) {
      const lineIndex = parseInt(linesMatch[1], 10); // Extract the index (e.g., 0, 1)
      const property = linesMatch[2]; // Extract the property after lines[n]. (e.g., somePropName)

      // Access the corresponding maintenanceOrderLine if the index exists
      const orderLine = this.workOrder?.maintenanceOrderLines[lineIndex];

      if (orderLine) {
        const oldValue = changeResult.oldValue ?? 'EMPTY';
        const newValue = changeResult.newValue ?? 'EMPTY';

        // Check if the property refers to attachments (e.g., lines[0].attachments[0])
        const nestedAttachmentMatch = property.match(/attachments\[(\d+)\]\.(.+)/);

        if (nestedAttachmentMatch) {
            // Extract attachment index and property
            const attachmentIndex = parseInt(nestedAttachmentMatch[1], 10); // Extract the index (e.g., 0, 1)
            const attachmentProperty = nestedAttachmentMatch[2]; // Extract the property after attachments[n]

            // Access the corresponding attachment in the orderLine
            const attachment = orderLine.attachedImages?.[attachmentIndex];

            if (attachment && property === 'name') {
                description = `Attachment ${this.mapProperty(attachmentProperty)} for Repair Code ${orderLine.maintenanceTypeCode} and Repair Location Code ${orderLine.maintenanceRepairLocationCode} has been changed from ${oldValue} to ${newValue}`;
            }
        } else {
            // Handle changes for non-attachment properties within lines[n]
            description = `${this.mapProperty(property)} for Repair Code ${orderLine.maintenanceTypeCode} and Repair Location Code ${orderLine.maintenanceRepairLocationCode} has been changed from ${oldValue} to ${newValue}`;
        }
      }  
    } 
    else if(attachmentsMatch){
      const attachmentIndex = parseInt(attachmentsMatch[1], 10); // Extract the index (e.g., 0, 1)
      const property = attachmentsMatch[2]; // Extract the property after lines[n]. (e.g., somePropName)

      // Access the corresponding maintenanceOrderLine if the index exists
      const attachment = this.workOrder?.attachedImages[attachmentIndex];

      if (attachment && property === 'name') {
        const oldValue = changeResult.oldValue ?? 'EMPTY';
        const newValue = changeResult.newValue ?? 'EMPTY';

        description = `${this.mapProperty(property)} for attachment has been changed from ${oldValue} to ${newValue}`;
      }
    }
    else {
      // Default description if it's not referring to lines[n]
      description = `${this.mapProperty(changeResult.propertyName)} changed from ${changeResult.oldValue === '' ? 'EMPTY' : changeResult.oldValue} to ${changeResult.newValue === '' ? 'EMPTY' : changeResult.newValue}`;
    }

    // Only return if description is non-empty
    if (description) {
      return {
          date: changeResult.timestamp,
          user: changeResult.createdBy.emailAddress,
          description
      };
  }

  // Return null if no relevant description
  return null;
  })?.filter(this.isNotNull) || [];  // Filter out null values

  
    // Map over auditLog statusHistory
    const statusHistoryData = this.auditLog.statusHistory?.map((statusHistoryItem) => {
      return {
        date: statusHistoryItem.timestamp,
        user: statusHistoryItem.createdBy.emailAddress,
        description: "Status changed to " + statusHistoryItem.value
      };
    }) || [];
  
    // Combine and sort both arrays by date in descending order
    auditLogData = [...changesData, ...statusHistoryData]
    .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  
    // Update auditLogTotal
    this.auditLogTotal.set([...auditLogData]);
  }

  // Type guard to filter out null values
  isNotNull<T>(value: T | null): value is T {
    return value !== null;
  }

  // Helper function to map API properties to UI-friendly names
  mapProperty(property: string): string {
    return propertyMapper[property] || property;  // Use the mapped property or fallback to the original if not mapped
  }
}

const propertyMapper: { [key: string]: string } = {
  'damagedAt': 'Third Party Port',
  'thirdPartyIndicator': 'TPI',
  'vendorsReference': 'Vendor Ref No.'
};