<form [formGroup]="bulkWorkOrderHeaderForm">
    <div class="aems-card m-b-12">
        <div class="pure-g">
            <div class="pure-u-1 pure-u-md-7-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 p-r-12">
                <mc-select ngDefaultControl formControlName="maintenanceShop" label="Shop Code" fit="small">
                    @for (shop of shops; track $index) {
                    <mc-option [value]="shop">{{ shop.maintenanceShopCode }} {{ shop.maintenanceShopName }}</mc-option>
                    }
                </mc-select>
            </div>
            <div class="pure-u-1 pure-u-md-7-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 p-r-12">
                <mc-input ngDefaultControl label="Cause" fit="small" readonly disabled value="1-Wear and Tear">

                </mc-input>
            </div>
            <div class="pure-u-1 pure-u-md-6-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 p-r-12">
                <mc-select ngDefaultControl formControlName="maintenanceRepairMode" label="Mode" fit="small">
                    @for (mode of modes; track $index) {
                    <mc-option [value]="mode">{{ mode.code }} {{ mode.description }}</mc-option>
                    }
                </mc-select>
            </div>
            <div class="pure-u-1 pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-4-24 pure-u-xxl-2-24 m-t-20">
                <div>INR</div>
                <div class="mds-neutral--weak__text-color mds-text--x-small-normal" style="line-height: 10px;">Currency
                </div>
            </div>
        </div>
    </div>
    <div class="aems-card">
        <div class="pure-g">
            <div class="pure-u-1 pure-u-md-7-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 mds-text--small-normal">
                Equipment Number
            </div>
            <div class="pure-u-1 pure-u-md-7-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 mds-text--small-normal">
                Vendor Ref No.(Optional)
            </div>
            <div class="pure-u-1 pure-u-md-6-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 mds-text--small-normal">
                Image(s)
            </div>
            <div class="pure-u-1 pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-4-24 pure-u-xxl-2-24 mds-text--small-normal">
                Action
            </div>

        </div>
        <div formArrayName="equipments" class="equipment-container">
            @for (item of equipments.controls; track item;) {
            <div class="pure-g p-t-8 p-b-8 p-l-4" [formGroupName]="$index" [class.background]="$odd">
                <div class="pure-u-1 pure-u-md-7-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 p-r-12">
                    <mc-input (keypress)="onKeyPressLastControlInRow($event,$index)" ngDefaultControl hiddenlabel
                        fit="small" formControlName="equipmentNumber">

                    </mc-input>
                </div>
                <div class="pure-u-1 pure-u-md-7-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24 p-r-12">
                    <mc-input ngDefaultControl hiddenlabel fit="small" formControlName="vendorsReference"></mc-input>
                </div>
                <div class="pure-u-1 pure-u-md-6-24 pure-u-lg-6-24 pure-u-xl-4-24 pure-u-xxl-2-24">
                    <app-file-upload  type="compact" shopCode="1VD"></app-file-upload>
                </div>
                <div class="pure-u-1 pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-4-24 pure-u-xxl-2-24">
                    <app-button-with-confirmation label="Delete" appearance="neutral" variant="filled" icon="trash"
                        [hiddenlabel]="true" [modalBody]="appTextSvc.BulkEquipmentDeleteConfirmation"
                        modalHeading="Delete Equipment" confirmButtonLabel="Yes" cancelButtonLabel="Cancel"
                        [onConfirm]="deleteEquipment($index)">
                        ></app-button-with-confirmation>
                </div>
            </div>
            }
        </div>
    </div>
</form>
<div class="m-b-12"></div>