import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, input, Input, OnInit, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RepairLineItemFormNoAttachmentAndParts } from '../../../../form-model/cwo-repair-line-item.form.model';
import { Mode } from '../../../../models/mode.model';
import { Shop } from '../../../../models/shop.model';
import { CodeDescription } from '../../../../models/code-description.model';
import { Repair } from '../../../../models/repair.model';
import { RepairLocation } from '../../../../models/repair-location.model';
import { getTPIChar, ThirdPartyIndicator } from '../../../../models/tpi.model';
import { IMcTypeaheadData } from '@maersk-global/mds-components-core/mc-typeahead/types';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-select';
import '@maersk-global/mds-components-core/mc-typeahead'
import { combineLatest, ReplaySubject, takeUntil } from 'rxjs';
import { utils } from '../../../../helper/utils';

@Component({
  selector: 'app-bulk-work-order-repair-line-item',
  standalone: true,
  imports: [ReactiveFormsModule],
  templateUrl: './bulk-work-order-repair-line-item.component.html',
  styleUrl: './bulk-work-order-repair-line-item.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BulkWorkOrderRepairLineItemComponent implements OnInit {
  @Input({ required: true }) repairLineItemForm!: FormGroup<RepairLineItemFormNoAttachmentAndParts>;
  index=input.required<number>();
  shop = input.required<Shop|null>();
	controlId=input.required<string>();
  damages = input.required<CodeDescription[]>();
	repairs = input.required<Repair[]>();
	repairLocations = input.required<RepairLocation[]|null>();
	tpiCodes = input.required<ThirdPartyIndicator[]|null>();
  @Output() repairLineItemRemoved = new EventEmitter<number>();
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  // Controls data
	damageCodesTypeahead: IMcTypeaheadData[] = []
	repairCodesTypeahead: IMcTypeaheadData[] = []
	repairLocationCodesTypeahead: IMcTypeaheadData[] = []
	tpiCodesTypeahead: IMcTypeaheadData[] = []
  constructor() { 
    
  }
  ngOnInit(): void {
    this.damageCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.damages());
    this.repairCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.repairs());
    this.repairLocationCodesTypeahead = this.mapCodeDescriptionToTypeaheadData(this.repairLocations()??[]);
    this.tpiCodesTypeahead = this.mapTPICodeToTypeaheadData(this.tpiCodes()??[]);
  }
  
  onRemoveRepairLineItemClicked(){
    this.repairLineItemRemoved.emit(this.index());
  }
  /**
	 * @description Event handler when the repair code is selected from the typeahead control
	 * @param event selected element from the typeahead control in template
	 * Resets the part array and parts form array control if the selected repair code does not have any parts
	 * Add validators and set value of Man Hr/Pc, pieces , and mat cost/pc control of a repair line item
	 * Checks if the selected repair code has any part associated with it, if yes, fetches the parts
	 */
	onRepairCodeOptionSelected(event: FocusEvent) {
		const repairCode=(event.target as HTMLInputElement).value;
		const selectedRepair = this.repairs().find((repair) => repair.code === repairCode);
		if (selectedRepair) {
			this.addRequiredAndMaxValidators(selectedRepair);
			this.repairLineItemForm.controls.manHoursPerPiece.setValue(utils.formatNumberToTwoDigitDecimal(selectedRepair.maxLabourHours));
			this.repairLineItemForm.controls.pieces.setValue(1);
			this.repairLineItemForm.controls.materialCostPerPiece.setValue(0);
		}
	}
  private addRequiredAndMaxValidators(
		selectedRepair: Repair
	) {
		let piecesControl = this.repairLineItemForm.controls.pieces;
		let materialCostPerPieceControl = this.repairLineItemForm.controls.materialCostPerPiece;
		piecesControl.clearValidators();
		piecesControl.addValidators([Validators.required, Validators.pattern(/^(0|[1-9]\d*)$/), Validators.max(selectedRepair.maxQuantity)]);
		piecesControl.updateValueAndValidity();
		materialCostPerPieceControl.clearValidators();
		materialCostPerPieceControl.addValidators([Validators.required, Validators.pattern(/^\d*\.?\d+$/), Validators.max(selectedRepair.maxMaterialCost)]);
		materialCostPerPieceControl.updateValueAndValidity();
	}
  private mapCodeDescriptionToTypeaheadData(data: CodeDescription[]): IMcTypeaheadData[] {
		return data.map((codeDescription) => {
			return {
				label: codeDescription.code,
				sublabel: codeDescription.description,
				value: codeDescription.code,
			};
		});
	}

	private mapTPICodeToTypeaheadData(data: ThirdPartyIndicator[]): IMcTypeaheadData[] {
		return data.map((codeDescription) => {
			return {
				label: getTPIChar(codeDescription.code),
				sublabel: codeDescription.displayText,
				value: codeDescription.code,
			};
		});
	}

  private calculateRepairItemTotalCost() {
		combineLatest([
			this.repairLineItemForm.controls.pieces.valueChanges,
			this.repairLineItemForm.controls.materialCostPerPiece.valueChanges]
		)
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([pieces, materialCostPerPiece]) => {
				this.repairLineItemForm.controls.itemTotalCost.setValue(utils.numberFormatter.format(pieces * materialCostPerPiece));
			});
	}

}
