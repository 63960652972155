import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Equipment } from "../../../../models/equipment.model";
import { ContainerGrade } from "../../../../models/container-grade.model";
import { Mode } from "../../../../models/mode.model";

export function containerGradeValidator(
    equipment: Equipment | null,
    containerGrades : ContainerGrade[], 
    mode: Mode | null
  ): ValidatorFn {
    return (control : AbstractControl) => {
      let enteredValue=control.value as string;
      const allowedMode : string = "03";
      if(!enteredValue){
        return null;
      }
      let filteredGrade = containerGrades.find(x => {
        return x.code === equipment?.gradeCode;
      });  
      if(filteredGrade?.repairMode === allowedMode && mode?.code === allowedMode){
      return filteredGrade?.notApplicableRepairCodes.find(x => x == enteredValue) ? { containerGradeValidator: { value: control.value }} : null ;
      }
      return null;
    }
}