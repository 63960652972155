import { AbstractControl, FormArray, FormGroup, ValidatorFn } from '@angular/forms';
import { utils } from '../../../../helper/utils';
import { PartLineItemForm } from '../../../../form-model/part-line-item.form.model';
export function partNumberUniqueValidator(): ValidatorFn {
	return (control: AbstractControl) => {
		let partsArray = control as FormArray<FormGroup<PartLineItemForm>>;
		let partNumbers: {partNumber:string}[] = [];
		partsArray.controls.forEach((partLine) => {
			let partNumber = partLine.controls.partNumber.value;
			partNumbers.push({ partNumber: partNumber});
		});
		var duplicateFound = utils.checkForDuplicates(partNumbers, 'partNumber');
		if (duplicateFound) {
			partsArray.controls[partsArray.controls.length-1].setErrors({ partNumberUniqueValidator: { value: 'Duplicate part added' } });
			return { partNumberUniqueValidator: { value: 'Duplicate part added' } };
		}else{
			partsArray.controls.forEach((partLineItem) => {
				if (partLineItem.errors) {
					partLineItem.errors['partNumberUniqueValidator'] = null;
					partLineItem.updateValueAndValidity();
				}
			});
			return null;
		}
	};
}
