@if(animation()==="progress"){
<ngx-skeleton-loader [theme]="
    {
        'margin-bottom':'0px',
        height:height(),
        width:width()
    }
    " [appearance]="type()" [count]="count()" animation="progress"></ngx-skeleton-loader>
}@else {
<ngx-skeleton-loader [count]="count()" animation="progress-dark" [theme]="{
      height: height(),
      width:width(),
      'background-color': '#323232',
      'margin-bottom':'0px'
    }">
</ngx-skeleton-loader>
}