import { Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { BulkWorkOrderHeader,SingleEquipmentForm } from '../../../form-model/bulk-workorder-header.form.model';
import { ButtonWithConfirmationComponent } from '../../../shared/button-with-confirmation/button-with-confirmation.component';
import { FileUploadComponent } from '../../../shared/aems-file-upload/file-upload.component';
import { ApplicationtTextService } from '../../../services/application-text.service';
import { ToastrService } from '../../../services/toastr-service';
import '@maersk-global/mds-components-core/mc-input';
import { Shop } from '../../../models/shop.model';
import { Mode } from '../../../models/mode.model';
import { MasterDataService } from '../../../services/master-data.service';
import { User } from '../../../models/user.model';
import { Equipment } from '../../../models/equipment.model';
import { AttachmentForm } from '../../../form-model/attachment.form.model';
import { BulkWorkOrderSharedDataService } from '../../../services/bulk-work-order-shared-data.service';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-bulk-work-order-header',
  standalone: true,
  imports: [ButtonWithConfirmationComponent, ReactiveFormsModule, FileUploadComponent],
  templateUrl: './bulk-work-order-header.component.html',
  styleUrl: './bulk-work-order-header.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class BulkWorkOrderHeaderEquipmentsComponent implements OnInit {
  bulkWorkOrderHeaderForm:FormGroup<BulkWorkOrderHeader>;
  @Output() headerValue=new EventEmitter<typeof this.bulkWorkOrderHeaderForm.getRawValue>();
  shops:Shop[]=[];
  modes:Mode[]=[];
  constructor(private fb: FormBuilder, 
    public appTextSvc: ApplicationtTextService, 
    private toastr: ToastrService,
    private masterDataSvc: MasterDataService,
    private bulkWorkOrderSharedDataService: BulkWorkOrderSharedDataService) {
    this.bulkWorkOrderHeaderForm = this.fb.group<BulkWorkOrderHeader>({
      maintenanceShop: this.fb.nonNullable.control({} as Shop),
      maintenanceRepairMode: this.fb.nonNullable.control({} as Mode),
      equipments: this.fb.array<FormGroup<SingleEquipmentForm>>([])
    });
    for (let i = 0; i < 1; i++)
    this.onAddEquipment();
  }
  ngOnInit(): void {
    forkJoin([this.masterDataSvc.getRepairMode("43"),this.masterDataSvc.getRepairMode("03")]).subscribe(([mode1,mode2])=>{
      this.modes.push(mode2);
      this.modes.push(mode1);
      this.bulkWorkOrderHeaderForm.controls.maintenanceRepairMode.setValue(this.modes[0]);
    });
    this.bulkWorkOrderHeaderForm.controls.maintenanceShop.valueChanges.subscribe((shop:Shop)=>{
      this.bulkWorkOrderSharedDataService.Shop=shop;
    });
    this.bulkWorkOrderHeaderForm.controls.maintenanceRepairMode.valueChanges.subscribe((mode:Mode)=>{
      this.bulkWorkOrderSharedDataService.Mode=mode;
    });
    this.getUserAssignedShop();
  }
  get equipments(): FormArray<FormGroup<SingleEquipmentForm>> {
    return this.bulkWorkOrderHeaderForm.get('equipments') as FormArray<FormGroup<SingleEquipmentForm>>;
  }
  get singleEquipmentForm(): FormGroup<SingleEquipmentForm> {
    const equipmentForm = this.fb.nonNullable.group<SingleEquipmentForm>({
      equipmentNumber: this.fb.nonNullable.control(''),
      equipment: this.fb.nonNullable.control({} as Equipment),
      vendorsReference: this.fb.nonNullable.control(''),
      attachedImages: this.fb.nonNullable.group<AttachmentForm>({} as AttachmentForm)
    });
    equipmentForm.controls.equipmentNumber.valueChanges.subscribe((equipmentNumber: string) => {
      this.masterDataSvc.getEquipmentDetails(equipmentNumber, this.bulkWorkOrderHeaderForm.controls.maintenanceShop.value.locationCode)
        .subscribe((equipment: Equipment) => {
          equipmentForm.controls.equipment.setValue(equipment);
        });
    });
    return equipmentForm;
  }
  onAddEquipment(): void {
    this.equipments.push(this.singleEquipmentForm);
  }
  deleteEquipment($index:number):()=>void{
    return ()=>{
      if(this.equipments.length === 1){
        this.toastr.showToastr(this.appTextSvc.AtleastOneEquipmentRequired,"warning");
        return;
      }
      this.equipments.removeAt($index);
    }
  }

  onKeyPressLastControlInRow(event: KeyboardEvent, index:number): void {
    if(index === this.equipments.length - 1){
    this.onAddEquipment()
    }
  }

  private getUserAssignedShop() {
		const userData = localStorage.getItem('user');
		if (userData === null) {
			return;
		}
		const user: User = JSON.parse(userData);
		this.shops = user.maintenanceShops;
    this.bulkWorkOrderHeaderForm.controls.maintenanceShop.setValue(this.shops[0]);
	}
}