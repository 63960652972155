import { Injectable, Signal, signal } from "@angular/core";
import { Warning } from "../../models/warning";

@Injectable({providedIn:'root'})
export class WarningSharedService {
    private warnings = signal<Warning[]>([]);
    addWarning(warning : Warning){
        this.warnings.update(val => {
            let updatedWarnings =  val.filter(x => x.validatorName !== warning.validatorName)
            return updatedWarnings;
        });
        this.warnings.update(val => {
            val.push(warning);
            return val;
        });
    }
    clearAllWarnings(){
        this.warnings.set([]);
    }
    deleteWarning(validatorName : string){
        this.warnings.update(val => {
            let updatedWarnings =  val.filter(x => x.validatorName !== validatorName)
            return updatedWarnings;
        });
    }
    getWarnings(): Signal<Warning[]>{
        return this.warnings.asReadonly();
    }
}
