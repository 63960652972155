import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Equipment } from '../../../../models/equipment.model';
// map or tap or any funciton in pipe does not get executed if control goes to catch error
// catch error is executed whenever the observable(in this case httpClient) throws an error
// In case of httpclient, if the response is not 200, it throws an error
// returning null from the function means the validation is successful
// returning an object means the validation failed, the key of object is the name of the validator

export function equipmentCometValidator(): ValidatorFn {
  return (control: AbstractControl):ValidationErrors|null => {
    var equipment=control.value as Equipment;
    if(equipment){
      return null;
    }else{
      return { equipmentCometValidator: { value: control.value } };
    }
  };
}