import { Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-menu';
import '@maersk-global/mds-components-core/mc-list';
import '@maersk-global/mds-components-core/mc-list-item';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-aems-button-group',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './aems-button-group.component.html',
  styleUrl: './aems-button-group.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AemsButtonGroupComponent {
  label = input.required<string>();
  fit = input<"small" | "medium" | "large">("small");
  appearance = input<"primary" | "secondary" | "neutral" | "inverse" | "error">("primary");
  variant = input<"filled" | "outlined" | "plain">("filled");
  hiddenlabel = input<boolean>(false);
  icon = input<string>("");
  parentButtonRouterLink = input.required<string>();
  menuItems = input.required<{ label: string, routerLink: string}[]>();
}
