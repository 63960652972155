import { HttpInterceptorFn } from '@angular/common/http';
import { AuthHelper } from '../auth/auth-helper';
import { environment } from '../../environments/environment';

export const addHeadersInterceptor: HttpInterceptorFn = (req, next) => {
  req.headers.delete('Authorization');
  let requestWithHeaders=req.clone({
    setHeaders:{
      Authorization:`Bearer ${AuthHelper.accessToken}`
    }
  });
  return next(requestWithHeaders);
};
