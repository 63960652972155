<div class="aems-upload-attachment-preview">
  @if(type==='full'){
  <ul>
    @for (file of files; track $index) {
    <li>
      @switch (file.file?.type||file.fileType) {
      @case("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      {
      <div class="docx-div" [class.upload-complete]="file.uploadedToBlob" (click)="onThumbnailClicked(file)">DOCX</div>
      } @case("application/pdf"){
      <div class="pdf-div" [class.upload-complete]="file.uploadedToBlob" (click)="onThumbnailClicked(file)">PDF</div>
      } @default {
      <img [class.upload-complete]="file.uploadedToBlob" [src]="file.fileUrl" (click)="onThumbnailClicked(file)"
        alt="scanning" />
      } }
      @if(!file.uploadedToBlob){
      <div class="progress-bar-container">
        <div class="progress-bar" [style.width.%]="file.progress$ | async"></div>
      </div>
      }
    </li>
    }
  </ul>
  }
  @if(type==='compact'){

  @if(files.length>0){
  <ul class="compact">
    <li class="compact">
      @switch (files[0].file?.type||files[0].fileType) {
      @case("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
      {
      <div class="docx-div" (click)="onThumbnailClicked(files[0])">DOCX</div>
      } @case("application/pdf"){
      <div class="pdf-div" (click)="onThumbnailClicked(files[0])">PDF</div>
      } @default {
      <img (click)="onThumbnailClicked(files[0])" [src]="files[0].fileUrl" alt="scanning" />
      }
      }
    </li>
    <li class="compact" style="display: flex;align-items: center;">
      @if(files.length>1){
      <div (click)="onThumbnailClicked(files[1])" class="more-files">+ {{files.length-1}}</div>
      }
    </li>
  </ul>
  }
  }
</div>