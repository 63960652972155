<div class="aems-card m-b-12">
    <div class="pure-g">
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.maintenanceShopCode}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Shop Code</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.vendorsReference??"-"}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Vendor Ref No.</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.equipmentNumber}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Equipment Number</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.maintenanceRepairModeCode}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Mode</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.isoCurrencyCode}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Currency</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>-</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Previous Repair Date</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.previousLocationCode??"-"}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Previous Repair Location</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>-</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Revision No.</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>-</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Gen Hours</div>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
            <div>{{workOrderHeader.maintenanceOrderCauseDisplayText}}</div>
            <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Cause</div>
        </div>
        @if(workOrderHeader.maintenanceOrderCause==="CONFIRMED_3RD_PARTY"){
            <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
                <div>{{workOrderHeader.damagedAt??"-"}}</div>
                <div class="mds-neutral--weak__text-color mds-text--x-small-normal">Third Party Port</div>
            </div>
        }
    </div>
</div>