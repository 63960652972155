import { CodeDescription } from "./code-description.model";
import { PreparationTime } from "./preparation-time.model";

export interface Mode extends CodeDescription{
damageType:DamageType;
preparationTimes: PreparationTime[]
}

export enum DamageType{
    STRUCTURAL=0,
    NON_STRUCTURAL=1,
}