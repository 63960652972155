import { AbstractControl, ValidatorFn } from "@angular/forms";
import { IMcTypeaheadData } from "@maersk-global/mds-components-core/mc-typeahead/types";

export function typeaheadCodeExistValidator(
    validCodes:IMcTypeaheadData[] 
  ): ValidatorFn {
    return (control: AbstractControl) => {
      let enteredValue=control.value as string;
      if(!enteredValue){
        return null;
      }
      return validCodes.find(code=>code.label===enteredValue)?null:{ typeaheadCodeExistValidator: { value: control.value }
    };
  }
}