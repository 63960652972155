
<div>
  <input
    accept="image/*,.pdf,.doc,.docx"
    multiple="true"
    style="display: none"
    type="file"
    (change)="onFileChange($event)"
    #fileUpload
  />
  <div
    aems-drag-drop
    (onFileDropped)="onFileDroped($event)"
    tabindex="0"
    class="aems-file-upload-drag-drop"
    [class.compact]="type() === 'compact'"
    (keyup.enter)="fileUpload.click()"
    (click)="fileUpload.click()"
  >
    <mc-icon icon="tray-arrow-up"></mc-icon>
    <div>Drag and drop or browse files</div>
  </div>
  
</div>

