import { Component, CUSTOM_ELEMENTS_SCHEMA, input } from '@angular/core';
import "@maersk-global/mds-components-core/mc-icon";
import { RouterLink, RouterLinkActive } from '@angular/router';
import { version } from '../../../environments/version';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [RouterLink,RouterLinkActive],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})
export class SideMenuComponent {
  toggleMenu=input.required<boolean>();
  get appVersion(): string {
    switch(<any>environment.ENVIRONMENT){
      case 'local':
        return "Running Locally"
      case 'cdt':
        return version.substring(0,7)
      case 'preprod':
        return version.substring(0,7)
      case 'prod':
          return version;
      default:
        return "No Version"
    }
  }
}
