import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class ApplicationtTextService{
    get BulkEquipmentDeleteConfirmation():string{
        return 'All details including uploaded images will be deleted permanently. Are you sure you want to proceed?';
    }

    get AtleastOneEquipmentRequired():string{
        return 'At least one equipment is required';
    }
}