import { CUSTOM_ELEMENTS_SCHEMA, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import '@maersk-global/mds-components-core/mc-loading-indicator';
import '@maersk-global/mds-components-core/mc-button';
import { AuthHelper } from './auth-helper';
import { MasterDataService } from '../services/master-data.service';
import { catchError } from 'rxjs';
@Component({
  selector: 'app-auth-callback',
  standalone: true,
  imports: [],
  templateUrl: './auth-callback.component.html',
  styleUrl: './auth-callback.component.scss',
  schemas:[CUSTOM_ELEMENTS_SCHEMA]
})
export class AuthCallback{
  message:string="";
  isLoginRedirect=true;
  userHasNoRole=false;
  constructor(private route: ActivatedRoute, private router: Router, private masterDataService: MasterDataService){
    this.route.queryParamMap.subscribe(params => {
      if (params.has('code')) {
        this.message="Login successful, redirecting to application."
        setTimeout(() =>  {
          this.message="Fetching user roles."
          this.masterDataService.getUser().pipe(catchError(err=>{
            localStorage.clear();
            this.message="You have no assigned role. Raise a SNOW ticket"
            this.userHasNoRole=true;
            console.error(err);
            throw err;
          })).subscribe((user) => {
            user.maintenanceShops.sort((a,b)=>a.maintenanceShopCode.localeCompare(b.maintenanceShopCode));
            AuthHelper.user = user;
            this.message="User roles fetched, redirecting to application."
            setTimeout(()=> this.router.navigate(['/workorders']),1000);
          })},2*1000);
        
      }else {
        this.isLoginRedirect=false;
       this.router.navigate(['/']);
      }
    });
  }
  onLoginAgainClicked(){
    this.router.navigate(['/']);
  }
}
