import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	Input,
	input,
	OnDestroy,
	OnInit,
	signal,
	Renderer2
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FormBuilder,
	Validators,
	ReactiveFormsModule,
	FormArray,
	AbstractControl,
	FormGroup
} from '@angular/forms';
import { ReplaySubject, timer } from 'rxjs';
import '@maersk-global/mds-components-core/mc-input';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-select';
import '@maersk-global/mds-components-core/mc-typeahead';
import { CodeDescription } from '../../../models/code-description.model';
import { Repair } from '../../../models/repair.model';
import { Mode } from '../../../models/mode.model';
import { ToastrService } from '../../../services/toastr-service';
import { Shop } from '../../../models/shop.model';
import { FileUploadComponent } from '../../../shared/aems-file-upload/file-upload.component';
import { ContainerGrade } from '../../../models/container-grade.model';
import { Equipment } from '../../../models/equipment.model';
import { reapirCodeAndRepairLocationUniqueValidator } from './validators/repair-code-and-repair-location-unique.validator';
import { CreateWorkOrderRepairPartsComponent } from '../repair-parts/cwo-repair-parts.component';
import { RepairLineItemForm } from '../../../form-model/cwo-repair-line-item.form.model';
import { RepairLineItemsForm } from '../../../form-model/damage-and-repair.form.model';
import { RepairLineItemComponent } from '../repair-line-item/repair-line-item.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { PartLineItemForm } from '../../../form-model/part-line-item.form.model';
import { ErrorComponent } from '../../../shared/error/error.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AttachmentForm } from '../../../form-model/attachment.form.model';
import { RepairLocation } from '../../../models/repair-location.model';
import { ThirdPartyIndicator } from '../../../models/tpi.model';
import { MaintenanceOrderLineItem } from '../../../models/maintenance-order-line-item';
import { utils } from '../../../helper/utils';
import { Currency } from '../../../models/currency.model';
import { RepairExclusion } from '../../../models/repair-exclusion.model';
import { mutualExclusiveRepairCodeValidator } from './validators/mutual-exclusive-repair-code.validator';
@Component({
	selector: 'app-cwo-line-items',
	standalone: true,
	imports: [
		CommonModule,
		ReactiveFormsModule,
		FileUploadComponent,
		CreateWorkOrderRepairPartsComponent,
		RepairLineItemComponent,
		ErrorComponent,
		NgxSkeletonLoaderModule
	],
	templateUrl: './cwo-line-items.component.html',
	styleUrl: './cwo-line-items.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [
		trigger('removeRepairLineItem', [
			transition(':leave', [animate('0.2s ease-in', style({ transform: 'translateX(-100%)' }))]),
		]),
	],
})
export class CreateWorkOrderLineItemsComponent implements OnInit, OnDestroy, AfterContentInit {
	@Input({ required: true }) damageAndRepairForm!: FormGroup<RepairLineItemsForm>;
	@Input() existingRepairLineItems: MaintenanceOrderLineItem[] | undefined;
	mode = input.required<Mode>();
	shop = input.required<Shop>();
	equipment = input.required<Equipment>();
	containerGrades = input.required<ContainerGrade[]>();
	workOrderCurrency = input.required<Currency | undefined>();
	damages = input.required<CodeDescription[]>();
	repairs = input.required<Repair[]>();
	repairExclusions = input.required<RepairExclusion[]>();
	repairLocations = input.required<RepairLocation[]>();
	tpiCodes = input.required<ThirdPartyIndicator[]>();
	private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
	openDeleteLineItemConfirmation: boolean = false;
	selectedLineItemIndexForDelete: number = -1;
	addRepairLineItemButtonDisabled = signal(true);
	constructor(
		private formBuilder: FormBuilder,
		private toastr: ToastrService,
		private renderer: Renderer2
	) {

	}
	ngAfterContentInit(): void {
		this.damageAndRepairForm.updateValueAndValidity();
	}

	ngOnDestroy(): void {
		this.damageAndRepairForm.controls.repairLineItems.clear();
		this.destroyed$.next(true);
		this.destroyed$.complete();
	}
	ngOnInit(): void {
		this.damageAndRepairForm.addControl('repairLineItems', this.formBuilder.nonNullable.array<FormGroup<RepairLineItemForm>>([]))
		this.damageAndRepairForm.addValidators([reapirCodeAndRepairLocationUniqueValidator(), mutualExclusiveRepairCodeValidator(this.repairExclusions)]);
		if (this.existingRepairLineItems && this.existingRepairLineItems.length > 0) {
			this.existingRepairLineItems.forEach(() => this.addOneRepairLineItemByDefault(false));
		} else {

			this.addOneRepairLineItemByDefault();
		}
		this.damageAndRepairForm.statusChanges.subscribe(status => {
			status === "VALID" ? this.addRepairLineItemButtonDisabled.set(false) : this.addRepairLineItemButtonDisabled.set(true)
		})
	}
	get repairLineItems(): FormArray<FormGroup<RepairLineItemForm>> {
		return this.damageAndRepairForm.controls.repairLineItems;
	}

	isControlInErrorState(control: AbstractControl | null): boolean | null {
		if (control?.invalid && control.touched) {
			return true;
		}
		return null;
	}

	onAddRepairLineItemClicked(setFocus: boolean = true) {
		let newLineItem = this.getSingleRepairLineItem();
		this.repairLineItems.push(newLineItem);
		if (setFocus) {
			let length = this.repairLineItems.length - 1;
			if (length > 0) {
				timer(200).subscribe(x => {
					let id = `#repair-line-item${length}-damage-code`;
					let element = this.renderer.selectRootElement(id);
					element.focus();
				});
			}
		}

	}
	onRemoveRepairLineItem(index: number) {
		if (this.repairLineItems.length === 1) {
			this.toastr.showToastr('Atleast one repair line item is required', 'warning');
			return;
		}
		this.selectedLineItemIndexForDelete = index;
		this.openDeleteLineItemConfirmation = true;
	}
	public onDeleteLineItemConfirmationCancel() {
		this.openDeleteLineItemConfirmation = false;
		this.selectedLineItemIndexForDelete = -1;
	}
	public onDeleteLineItemConfirmationClosed() {
		this.openDeleteLineItemConfirmation = false;
		this.selectedLineItemIndexForDelete = -1;
	}
	public onDeleteLineItemConfirmation() {
		this.repairLineItems.removeAt(this.selectedLineItemIndexForDelete);
		this.openDeleteLineItemConfirmation = false;
		this.selectedLineItemIndexForDelete = -1;
	}
	private getSingleRepairLineItem(): FormGroup<RepairLineItemForm> {
		let repairLineItem = this.formBuilder.nonNullable.group<RepairLineItemForm>({
			damageCode: this.formBuilder.nonNullable.control<string>(''),
			repairCode: this.formBuilder.nonNullable.control<string>(''),
			repairLocationCode: this.formBuilder.nonNullable.control<string>(''),
			tpiCode: this.formBuilder.nonNullable.control<string>(''),
			pieces: this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^(0|[1-9]\d*)$/)),
			materialCostPerPiece: this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d*\.?\d+$/)),
			manHoursPerPiece: this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d*\.?\d+$/)),
			itemTotalCost: this.formBuilder.nonNullable.control<string>({ value: '', disabled: true }),
			attachments: this.formBuilder.nonNullable.group<AttachmentForm>({} as AttachmentForm),
			parts: this.formBuilder.nonNullable.array<FormGroup<PartLineItemForm>>([]),
		}) as FormGroup<RepairLineItemForm>;
		return repairLineItem;
	}
	private addOneRepairLineItemByDefault(setFocus: boolean = true) {
		this.onAddRepairLineItemClicked(setFocus);
	}
}
