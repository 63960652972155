import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RepairLineItemsFormBulk } from '../../../form-model/damage-and-repair.form.model';
import { RepairLineItemFormNoAttachmentAndParts } from '../../../form-model/cwo-repair-line-item.form.model';
import { BulkWorkOrderSharedDataService } from '../../../services/bulk-work-order-shared-data.service';
import { catchError, combineLatest, forkJoin, Observable, Subscription, switchMap, timer } from 'rxjs';
import { MasterDataService } from '../../../services/master-data.service';
import { Repair } from '../../../models/repair.model';
import { CodeDescription } from '../../../models/code-description.model';
import { CommonModule } from '@angular/common';
import { BulkWorkOrderRepairLineItemComponent } from './bulk-work-order-repair-line-item/bulk-work-order-repair-line-item.component';
import { RepairLocation } from '../../../models/repair-location.model';
import { ThirdPartyIndicator } from '../../../models/tpi.model';
import { APICallStatus } from '../../../models/api-call-status.mode';
import { Shop } from '../../../models/shop.model';
import { LoaderComponent } from '../../../shared/loader/loader.component';

@Component({
  selector: 'app-bulk-work-order-repair-line-items',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, BulkWorkOrderRepairLineItemComponent,LoaderComponent],
  templateUrl: './bulk-work-order-repair-line-items.component.html',
  styleUrl: './bulk-work-order-repair-line-items.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkWorkOrderRepairLineItemsComponent implements OnInit {
  repairLineItemsForms!: FormGroup<RepairLineItemsFormBulk>;
  repairs=signal<Repair[]>([]);
  damages=signal<CodeDescription[]>([]);
  repairLocations= signal<RepairLocation[]>([]);
  tpiCodes=signal<ThirdPartyIndicator[]>([]);
  modeAndShopSelected=signal(false);
  apiCallsForMasterData=signal<APICallStatus>("NOT_STARTED");
  selectedShop=signal<Shop|null>(null);
  private repairAndDamageCodesApiCallSubscription: Subscription | undefined;

  constructor(public bulkWorkOrderSharedDataService: BulkWorkOrderSharedDataService,
    public masterDataService: MasterDataService,
    private formBuilder: FormBuilder) {
    this.initializeOrResetRepairLineItemsForm();
  }

  ngOnInit(): void {
    combineLatest([this.bulkWorkOrderSharedDataService.Mode, this.bulkWorkOrderSharedDataService.Shop]).
      subscribe(([mode, shop]) => {
        if (mode && shop) {
          this.selectedShop.set(shop);
          this.modeAndShopSelected.set(true);
          this.apiCallsForMasterData.set("IN_PROGRESS");
          this.initializeOrResetRepairLineItemsForm();
          this.repairAndDamageCodesApiCallSubscription?.unsubscribe();
          timer(100).subscribe(() => {
            this.repairAndDamageCodesApiCallSubscription = forkJoin(
              [this.masterDataService.getRepairCodes(mode.code, shop.maintenanceShopCode, shop.maintenanceShopCurrency.exchangeRateFromUsd),
              this.masterDataService.getDamageCodes(mode.damageType),
              this.masterDataService.getRepairLocations(),
              this.masterDataService.getRepairTpiCodes()]).
              pipe(catchError((error) => {
                console.error("Error while fetching repair and damage codes", error);
                throw error;
              })).
              subscribe(([repairs, damages, repairLocations, tpiCodes]) => {
                this.repairs.set(repairs);
                this.damages.set(damages);
                this.repairLocations.set(repairLocations);
                this.tpiCodes.set(tpiCodes);
                this.apiCallsForMasterData.set("COMPLETED_SUCCESFULLY");
                this.repairLineItems.push(this.getSingleRepairLineItem());
              });
          });
          
        }
      });

  }
  get repairLineItems(): FormArray<FormGroup<RepairLineItemFormNoAttachmentAndParts>> {
    return this.repairLineItemsForms.get('repairLineItems') as FormArray<FormGroup<RepairLineItemFormNoAttachmentAndParts>>;
  }

  private initializeOrResetRepairLineItemsForm(): void {
    this.repairLineItemsForms = new FormGroup<RepairLineItemsFormBulk>({
      repairLineItems: new FormArray<FormGroup<RepairLineItemFormNoAttachmentAndParts>>([])
    });
  }

  private getSingleRepairLineItem(): FormGroup<RepairLineItemFormNoAttachmentAndParts> {
    let repairLineItem = this.formBuilder.nonNullable.group<RepairLineItemFormNoAttachmentAndParts>({
      damageCode: this.formBuilder.nonNullable.control<string>(''),
      repairCode: this.formBuilder.nonNullable.control<string>(''),
      repairLocationCode: this.formBuilder.nonNullable.control<string>(''),
      tpiCode: this.formBuilder.nonNullable.control<string>(''),
      pieces: this.formBuilder.nonNullable.control<number>(0),
      materialCostPerPiece: this.formBuilder.nonNullable.control<number>(0),
      manHoursPerPiece: this.formBuilder.nonNullable.control<number>(0),
      itemTotalCost: this.formBuilder.nonNullable.control<string>({ value: '0', disabled: true })
    }) as FormGroup<RepairLineItemFormNoAttachmentAndParts>;
    return repairLineItem;
  }

  onRemoveRepairLineItem(index: number) {
    this.repairLineItems.removeAt(index);
  }
  onAddRepairLineItemClicked() {
    this.repairLineItems.push(this.getSingleRepairLineItem());
  }
}
