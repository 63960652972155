import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { SearchComponent } from '../../components/list-work-orders/search/search.component';
import { WorkOrderGridComponent } from '../../components/list-work-orders/work-order-grid/work-order-grid.component';
import { FiltersComponent } from '../../components/list-work-orders/filters/filters.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import {
  FormBuilder,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { WorkOrderFilterForm } from '../../form-model/work-order-filter.form.model';
import { Mode } from '../../models/mode.model';
import { MaintenanceOrderStatus } from '../../models/maintenance-order-status.model';
import { Shop } from '../../models/shop.model';
import { WorkOrderSearchTextForm } from '../../form-model/work-order-search-text.form.model';
@Component({
  selector: 'app-work-order-list-page',
  standalone: true,
  imports: [SearchComponent, WorkOrderGridComponent, FiltersComponent, NgxSkeletonLoaderModule, ReactiveFormsModule],
  templateUrl: './list-work-orders.component.html',
  styleUrl: './list-work-orders.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ListWorkOrdersComponent {

  filterFormGroup = this.formBuilder.group({
    workOrderFilterForm: this.formBuilder.group<WorkOrderFilterForm>({} as WorkOrderFilterForm),
    workOrderSearchTextForm: this.formBuilder.group<WorkOrderSearchTextForm>({} as WorkOrderSearchTextForm),
  });

  constructor(private formBuilder: FormBuilder) {
  }
}
