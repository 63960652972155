import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AemsFile } from '../../../models/aems-file.model';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-file-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './file-preview.component.html',
  styleUrl: './file-preview.component.scss'
})
export class FilePreviewComponent {
@Input() files:AemsFile[]=[];
@Input() type:'full' | 'compact'='full';
@Output() thumbnailClicked=new EventEmitter<AemsFile>();
onThumbnailClicked(file:AemsFile){
this.thumbnailClicked.emit(file);
}
}
