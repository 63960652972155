
<mc-table disablerowhighlightonhover="true" [data]="tableData" fit="small" [columns]="repairLineItemTableColumns" tabularFigures="true" expand>
@for (item of tableData; track $index) {
@if(item.parts && item.parts.length>0){
    @for (partItem of item.parts; track $index) {
    <app-list-repair-part-items  [slot]="item.id + '_expanded'" [repairPart]="partItem" [maintainceOrderStatus]="maintainceOrderStatus" ></app-list-repair-part-items>
    }
}
<div [slot]="item.id +'_attachments'">
    <app-file-upload type="compact" [readOnly]="true" [inputFiles]="item.files"></app-file-upload>
</div>
}

</mc-table>
