import {
	AfterViewInit,
	CUSTOM_ELEMENTS_SCHEMA,
	Component,
	Input,
	OnInit,
	computed,
	effect,
	input,
} from '@angular/core';
import { ManHoursForm } from '../../../../form-model/cwo-man-hours.form.model';
import {
	FormBuilder,
	FormControl,
	FormGroup,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { Mode } from '../../../../models/mode.model';
import { totalHoursSumValidator } from './validators/total-hours-sum.validator';
import { ErrorComponent } from '../../../../shared/error/error.component';
import { utils } from '../../../../helper/utils';
import { MaintenanceOrderManHours } from '../../../../models/maintenance-order-man-hours';

@Component({
	selector: 'app-cwo-man-hours',
	standalone: true,
	imports: [ReactiveFormsModule, CommonModule, ErrorComponent],
	templateUrl: './cwo-man-hours.component.html',
	styleUrl: './cwo-man-hours.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateWorkOrderManHoursComponent implements OnInit {
	@Input({ required: true }) manHoursForm!: FormGroup<ManHoursForm>;
	totalManHoursWithoutPrepTime = input.required<number>();
	manHoursThatQualifyForPrepTime=input.required<number>()
	isPrepTimeApplicable=input.required<boolean>()
	existingManHours = input<MaintenanceOrderManHours[]>([]);
	mode = input.required<Mode | null>();
	prepTime = computed(() => {
		if(!this.isPrepTimeApplicable()){
			return 0;
		}
		return (
			this.mode()
				?.preparationTimes.sort((a, b) => a.maxTime - b.maxTime)
				.find((pt) => pt.maxTime >= this.manHoursThatQualifyForPrepTime())?.preparationHours ?? 0
		);
	});
	totalManHoursWithPrepTime = computed(() => {
		return utils.formatNumberToTwoDigitDecimal(this.prepTime() + this.totalManHoursWithoutPrepTime());
	});
	constructor(private formBuilder: FormBuilder) {}
	ngOnInit(): void {
		this.addControlsToFormGroup();
		this.preFillOvertimeManhoursOnEdit();
	}
	private updates = effect(() => {
		const totalManHoursDoubleTime=this.manHoursForm.controls.totalManHoursDoubletime.value;
		const totalManHoursMisc=this.manHoursForm.controls.totalManHoursMisc.value;
		const totalManHoursOvertime=this.manHoursForm.controls.totalManHoursOvertime.value;
		const totalNonOrdinraryHours=totalManHoursDoubleTime+totalManHoursMisc+totalManHoursOvertime;
		this.manHoursForm.controls.preparationTime.setValue(this.prepTime());
		this.manHoursForm.controls.totalManHours.setValue(this.totalManHoursWithPrepTime());
		this.manHoursForm.controls.ordinaryHours.setValue(utils.formatNumberToTwoDigitDecimal(this.totalManHoursWithPrepTime()-totalNonOrdinraryHours));
		console.log("Effect ran");
	});
	isControlInErrorState(control: FormControl): boolean | null {
		if (control.invalid && control.touched) {
			return true;
		}
		return null;
	}

	private addControlsToFormGroup() {
		this.manHoursForm.addControl(
			'preparationTime',
			this.formBuilder.nonNullable.control<number>(0)
		);
		this.manHoursForm.addControl('totalManHours', this.formBuilder.nonNullable.control<number>(0));
		this.manHoursForm.addControl(
			'ordinaryHours',
			this.formBuilder.nonNullable.control<number>(0, [
				Validators.pattern(/^\d+\.?\d{0,2}$/),
				Validators.required,
			])
		);
		this.manHoursForm.addControl(
			'totalManHoursOvertime',
			this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d+\.?\d{0,2}$/))
		);
		this.manHoursForm.addControl(
			'totalManHoursDoubletime',
			this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d+\.?\d{0,2}$/))
		);
		this.manHoursForm.addControl(
			'totalManHoursMisc',
			this.formBuilder.nonNullable.control<number>(0, Validators.pattern(/^\d+\.?\d{0,2}$/))
		);
		this.manHoursForm.addValidators([totalHoursSumValidator()]);
	}

	private preFillOvertimeManhoursOnEdit(){
		if(this.existingManHours().length>0){
			const regularManHours=this.existingManHours().find(mh=>mh.manHoursType==="REGULAR");
			const overtimeManHours=this.existingManHours().find(mh=>mh.manHoursType==="OVERTIME");
			const doubleTimeManHours=this.existingManHours().find(mh=>mh.manHoursType==="DOUBLETIME");
			const miscManHours=this.existingManHours().find(mh=>mh.manHoursType==="MISCELLANEOUS");
			this.manHoursForm.controls.ordinaryHours.setValue(regularManHours?.manHours??0);
			this.manHoursForm.controls.totalManHoursOvertime.setValue(overtimeManHours?.manHours??0);
			this.manHoursForm.controls.totalManHoursDoubletime.setValue(doubleTimeManHours?.manHours??0);
			this.manHoursForm.controls.totalManHoursMisc.setValue(miscManHours?.manHours??0);
			
	}
}
}
