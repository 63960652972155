import {AbstractControl, ValidationErrors,FormGroup, ValidatorFn } from '@angular/forms';
import { WarningSharedService } from '../../warning-shared-service';
import { HeaderForm } from "../../../../form-model/cwo-header.form.model";
import { ToastrService } from "../../../../services/toastr-service";
export function locationMismatchValidatorWarning(
  toastrService: ToastrService // Pass ToastrService here
  
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let headerForm = control as FormGroup<HeaderForm>;
    const equipment = headerForm.controls.proxyEquipmentNumberForAsyncValidations.value;
    const shop = headerForm.controls.shopCode.value;
    //warningSharedService.deleteWarning(locationMismatchValidatorWarning.name);
    if (!equipment || !shop) {
      return null;
    }

    if (equipment.presentLocation !== shop.locationCode) {
      toastrService.showToastr('Shop location does not match the equipment location', 'warning',60000);
      /*let warningObj = {validatorName: locationMismatchValidatorWarning.name, message: 'Shop location does not match the equipment location'}
      warningSharedService.addWarning(warningObj);*/
    }
    /*else{
    toastrService.closeToast();
    }*/
    return null;
  }
}


