<form [formGroup]="repairLineItemForm">
    <div class="pure-g line-item">
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="200px" ngDefaultControl label="Damage Code" placeholder="Type Code" fit="small"
                formControlName="damageCode" [data]="damageCodesTypeahead"
                oninput="this.value = this.value.toUpperCase()" [id]="controlId()+'-damage-code'">
            </mc-typeahead>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="300px" ngDefaultControl label="Repair Code" placeholder="Type Code" fit="small"
                formControlName="repairCode" [data]="repairCodesTypeahead" (blur)="onRepairCodeOptionSelected($event)">
            </mc-typeahead>
        </div>
        <div class="pure-u-md-6-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="300px" ngDefaultControl label="Repair Loc code" placeholder="Type Code" fit="small"
                formControlName="repairLocationCode" [data]="repairLocationCodesTypeahead"
                oninput="this.value = this.value.toUpperCase()">
            </mc-typeahead>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-2-24 p-r-8 m-b-12">
            <mc-typeahead highlight="true" showlistonfocus="true" maxoptions="20" optionsheight="400px"
                optionswidth="400px" ngDefaultControl label="TPI" placeholder="Type Code" fit="small"
                formControlName="tpiCode" [data]="tpiCodesTypeahead" oninput="this.value = this.value.toUpperCase()">
            </mc-typeahead>
        </div>
        <div class="pure-u-md-2-24 pure-u-lg-2-24 pure-u-xl-1-24 p-r-8 m-b-12">
            <mc-input ngDefaultControl label="Pcs" fit="small" formControlName="pieces">
            </mc-input>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-3-24 pure-u-xl-2-24 p-r-8 m-b-12">
            <mc-input readonly ngDefaultControl label="Man Hrs/pc" fit="small"
                formControlName="manHoursPerPiece"></mc-input>
        </div>
        <div class="pure-u-md-4-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            <mc-input ngDefaultControl label="Material cost/pc" fit="small" formControlName="materialCostPerPiece"
                [suffix]="shop()?.maintenanceShopCurrency?.currencyCode">
            </mc-input>
        </div>
        <div class="pure-u-md-10-24 pure-u-lg-4-24 pure-u-xl-3-24 p-r-8 m-b-12">
            <mc-input ngDefaultControl label="Item Total" fit="small" formControlName="itemTotalCost"
                [suffix]="shop()?.maintenanceShopCurrency?.currencyCode" readonly>
            </mc-input>
        </div>
        <div style="text-align: right;" class="pure-u-md-4-24 pure-u-lg-1-24 pure-u-xl-1-24 p-r-8 m-b-12 m-t-20">
            <mc-button class="m-r-4 m-t-4 repair-line-item-delete-button" label="Button " variant="outlined"
                appearance="neutral" fit="small" icon="trash" hiddenlabel padding="default"
                (click)="onRemoveRepairLineItemClicked()">
            </mc-button>
        </div>
    </div>
</form>