import { Component, OnInit, effect, signal, ChangeDetectionStrategy, input, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, Output, Input } from '@angular/core';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WorkOrderService } from '../../../../services/work-order.service';
import { TotalCost } from '../../../../models/total-cost-model';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TotalCostForm } from '../../../../form-model/total-cost.form.model';
import { CreateWorkOrderTotalCostDetailsComponent } from '../total-cost-details/cwo-total-cost-details/cwo-total-cost-details.component';
import { ReplaySubject, takeUntil } from 'rxjs';
import { utils } from '../../../../helper/utils';
import { APICallStatus } from '../../../../models/api-call-status.mode';
interface CostSummaryTable {
  id:string
  name: string;
  shopCostUsd: number;
  shopCostLocal: number;
}
@Component({
  selector: 'app-cwo-total-cost',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgxSkeletonLoaderModule,CreateWorkOrderTotalCostDetailsComponent],
  templateUrl: './cwo-total-cost.component.html',
  styleUrl: './cwo-total-cost.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CreateWorkOrderTotalCostComponent implements OnInit {
  cost = input.required<TotalCost>({});
  totalManHoursSummary = input<number>(0);
  @Input() workOrderMode: "create" | "view" = "create";
  repairLineItemsAndManhourFormValid=input<boolean>(true);
  apiCallStatus=input<APICallStatus>("NOT_STARTED");
  @Input() totalCostForm!: FormGroup<TotalCostForm>;

  dataCalculationSummary:CostSummaryTable[]=[];
  dataResponsibilityTotal:CostSummaryTable[]=[];
  dataWithTaxTotal:CostSummaryTable[]=[];
  showDetails: boolean = false;
  openCalculationDetails: boolean = false;

  columnsCalculationSummary: Array<TableColumn> = [];
  columnsResponsibilityTotal: Array<TableColumn> = [];
  columnsWithTaxTotal: Array<TableColumn> = [];
  hidecolumns: string[] = [];
  rowStyles = `
  .mds-table tr td:nth-child(1) {
    font-weight: bold;
  }`;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    if (this.totalCostForm) {
      this.totalCostForm.addControl('totalCost', this.formBuilder.nonNullable.control<number>(0));
    }
    this.defineColumns();
    this.getCalculationSummary(this.cost());
    this.getResponsibilityTotals(this.cost());
    this.getTaxTotals(this.cost())
  }

  effect_for_total_cost_change = effect(() => {
    this.defineColumns();
    this.getCalculationSummary(this.cost());
    this.getResponsibilityTotals(this.cost());
    this.getTaxTotals(this.cost())
  })



  toggleDetails() {
    this.showDetails = true;
    this.openCalculationDetails = this.showDetails;
  }

  onCalculationDetailsClose() {
    this.openCalculationDetails = false;
    this.showDetails=false;
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }



  private defineColumns() {
    let shopCurrencyISO = this.cost().shopCurrencyCode;
    if (shopCurrencyISO.toUpperCase() === "USD") {
      this.hidecolumns.push("shopCostLocal")
    }

    this.columnsCalculationSummary = [
      { id: 'name', label: 'Calculation Summary', width: '60%' },
      { id: 'shopCostUsd', label: 'Shop Cost(USD)', align: 'right', width: '20%' },
      { id: 'shopCostLocal', label: `Shop Cost(${shopCurrencyISO})`, align: 'right', width: '20%' }
    ];

    this.columnsResponsibilityTotal = [
      { id: 'name', label: 'Responsibility Code Totals', width: '60%' },
      { id: 'shopCostUsd', label: 'Shop Cost(USD)', align: 'right', width: '20%' },
      { id: 'shopCostLocal', label: `Shop Cost(${shopCurrencyISO})`, align: 'right', width: '20%' }
    ];

    this.columnsWithTaxTotal = [
      { id: 'name', label: 'Totals', width: '60%' },
      { id: 'shopCostUsd', label: 'Shop Cost(USD)', align: 'right', width: '20%' },
      { id: 'shopCostLocal', label: `Shop Cost(${shopCurrencyISO})`, align: 'right', width: '20%' }
    ];
  }

  private getCalculationSummary(cost: TotalCost) {
    let totalMaterialCost = 0;
    let totalPartsCost = 0;
    cost.lineItems.forEach(element => {
      totalMaterialCost = totalMaterialCost + element.totalMaterialCost
      element.parts.forEach(part => {
        totalPartsCost += (part.finalPriceAfterDiscountAndMarkupPerUnit * part.quantity);
      })
    });
    this.dataCalculationSummary=[{
      id:"totalLoborCost",
      name: "Total Labor Cost",
      shopCostLocal:utils.formatNumberToTwoDigitDecimal(cost.labourHourDistribution.totalLabourHoursPriceBeforeTax),
      shopCostUsd: utils.formatNumberToTwoDigitDecimal(cost.labourHourDistribution.totalLabourHoursPriceBeforeTax * cost.shopExchangeRateToUsd)
    }, {
      id:"totalMaterialCost",
      name: "Total Material Cost",
      shopCostLocal: utils.formatNumberToTwoDigitDecimal(totalMaterialCost),
      shopCostUsd: utils.formatNumberToTwoDigitDecimal(totalMaterialCost * cost.shopExchangeRateToUsd)
    }, {
      id:"totalPartCost",
      name: "Total Part Cost",
      shopCostLocal: utils.formatNumberToTwoDigitDecimal(totalPartsCost),
      shopCostUsd: utils.formatNumberToTwoDigitDecimal(totalPartsCost * cost.shopExchangeRateToUsd)
    }];
  }

  private getResponsibilityTotals(cost: TotalCost) {
    const wearAndTearCost = cost.lineItems
      .filter(item => item.thirdPartyIndicator === "WEAR_AND_TEAR")
      .reduce((sum, item) => sum + item.materialCost, 0);
    const thirdPartyCost = cost.lineItems
      .filter(item => item.thirdPartyIndicator === "THIRD_PARTY")
      .reduce((sum, item) => sum + item.totalMaterialCost, 0);
    this.dataResponsibilityTotal=[{
      id:"totalWearAndTear",
      name: "Total Wear and Tear",
      shopCostLocal: utils.formatNumberToTwoDigitDecimal(wearAndTearCost),
      shopCostUsd: utils.formatNumberToTwoDigitDecimal(wearAndTearCost * cost.shopExchangeRateToUsd)
    }, {
      id:"totalThirdParty",
      name: "Total Third Party",
      shopCostLocal: utils.formatNumberToTwoDigitDecimal(thirdPartyCost),
      shopCostUsd: utils.formatNumberToTwoDigitDecimal(thirdPartyCost * cost.shopExchangeRateToUsd)
    }];

  }
  private getTaxTotals(cost: TotalCost) {
    const totalWithoutTax = cost.totalOrderCostWithoutTax;
    const totalWithTax = cost.totalOrderCostWithTax;
    this.dataWithTaxTotal=[
      {
        id:"totalExcludingTax",
        name: "Total cost excluding Tax/VAT",
        shopCostLocal: utils.formatNumberToTwoDigitDecimal(totalWithoutTax),
        shopCostUsd: utils.formatNumberToTwoDigitDecimal(totalWithoutTax * cost.shopExchangeRateToUsd)
      },
      {
        id:"totalIncludingTax",
        name: "Total cost including Tax/VAT",
        shopCostLocal: utils.formatNumberToTwoDigitDecimal(totalWithTax),
        shopCostUsd: utils.formatNumberToTwoDigitDecimal(totalWithTax * cost.shopExchangeRateToUsd)
      },
    ];

  }
}


