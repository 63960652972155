import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { catchError, map, of, tap } from 'rxjs';
import { MasterDataService } from '../../../../services/master-data.service';
import { Equipment } from '../../../../models/equipment.model';
import { WarningSharedService } from '../../warning-shared-service';
import { ToastrService } from '../../../../services/toastr-service';

export function equipmentMaximumRepairCostValidator(
  masterDataService: MasterDataService,
  //warningSharedService: WarningSharedService
  toastrService: ToastrService
): AsyncValidatorFn {
  return (control: AbstractControl) => {
    var equipment=control.value as Equipment;
    if(!equipment)
      return of(null);
    if(control.invalid)
      return of(null)
    //warningSharedService.deleteWarning(equipmentMaximumRepairCostValidator.name);
    return masterDataService
      .getEquipmentRemark(equipment?.equipmentNumber??'')
      .pipe(
        map((result) => {
          if(result){
            if(result.displayRemark === true && result.maximumRepairCost ===0){
              return { equipmentMaximumRepairCostValidator : { value: result.message }} 
            }
            else if(result.displayRemark === true && (result.maximumRepairCost === null || result.maximumRepairCost)){
              toastrService.showToastr(result.message, 'warning',60000); 
              /*let warningObj = {validatorName: equipmentMaximumRepairCostValidator.name, message: result.message}
              warningSharedService.addWarning(warningObj);*/
            }
          }
          return null;
        }),
        catchError((error) => {
          return of({ equipmentMaximumRepairCostValidator: { value: 'Unable to verify maximum repair limit for this equipment' } });
        }));
  };
}
