import { CUSTOM_ELEMENTS_SCHEMA, ChangeDetectionStrategy, Component, effect, input, signal } from '@angular/core';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { Equipment } from '../../../models/equipment.model';

@Component({
  selector: 'app-cwo-alarm-details',
  standalone: true,
  imports: [],
  templateUrl: './cwo-alarm-details.component.html',
  styleUrl: './cwo-alarm-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection:ChangeDetectionStrategy.Default
})
export class CreateWorkOrderAlarmDetailsComponent {

  equipment=input<Equipment>({} as Equipment);
  alarmColumns: Array<TableColumn> = [
    {
      id: 'alarmCode',
      label: 'Alarm Code',
    },
    {
      id: 'alarmTime',
      label: 'Alarm Time',
    },
    {
      id: 'alarmDescription',
      label: 'Description',
    },
    {
      id: 'alarmCriticality',
      label: 'Alarm Criticality',
    },
    {
      id: 'alarmType',
      label: 'Alarm Type',
    }
  ];
}
