import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ToastrService } from '../../../../services/toastr-service';

export function duplicateRepairCodeValidator(
  pastRepairs: string[],toastrService:ToastrService): ValidatorFn {
  return (control: AbstractControl) => {
    var repairCode = control.value as string;
    let duplicateRepair = pastRepairs.find(x => x === repairCode)
    if (duplicateRepair) {
      toastrService.showToastr(
        `Repair ${duplicateRepair} has already been done on the equipment, are you sure it is required?`,
        'warning',
        60000
      );
    }
    return null;
  }
}
