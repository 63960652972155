import { Component } from '@angular/core';

@Component({
  selector: 'app-bulk-work-order-remark',
  standalone: true,
  imports: [],
  templateUrl: './bulk-work-order-remark.component.html',
  styleUrl: './bulk-work-order-remark.component.scss'
})
export class BulkWorkOrderRemarkComponent {

}
