@if(!masterdataLoading){
<form [formGroup]="headerForm">
  <app-error [formContainer]="headerForm"></app-error>
  <div class="aems-card m-b-12">
    <div class="pure-g">
      <div class="pure-u-md-6-24 pure-u-lg-8-24 p-b-16 p-r-8">
        <mc-select ngDefaultControl formControlName="shopCode" label="Shop Code" fit="small"
          [attr.invalid]="isControlInErrorState(headerForm.controls.shopCode)"
          [class.custom-error]="errorControls().indexOf('shopCode') >= 0">
          @for (shop of shops; track $index) {
          <mc-option [value]="shop">{{ shop.maintenanceShopCode }} {{ shop.maintenanceShopName }}</mc-option>
          }
        </mc-select>
      </div>

      <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
        @if(equipmentDetailLoading() || headerForm.controls.proxyEquipmentNumberForAsyncValidations.pending){
        <mc-loading-indicator class="loading-icon-inside-control" fit="small" label="Validating"
          orientation="horizontal" hiddenlabel>
        </mc-loading-indicator>
        }
        <mc-input ngDefaultControl oninput="this.value = this.value.toUpperCase()" label="Equipment Number"
          placeholder="MAEU1234123" formControlName="equipmentNumber"
          [attr.invalid]="isControlInErrorState(headerForm.controls.equipmentNumber)" fit="small"
          [class.custom-error]="errorControls().indexOf('equipmentNumber') >= 0">

          @if(headerForm.controls.equipmentNumber.hasError('required')) {
          <span slot="errormessage">Equipment Number is required</span>
          } @if(headerForm.controls.equipmentNumber.hasError('pattern')){
          <span slot="errormessage">Incorrect equipment number</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('workorderAlreadyExistValidator')){
          <span slot="errormessage">Workorder already exist for the equipment number</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('equipmentCometValidator')){
          <span slot="errormessage">Equipment does not exist</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('equipmentMaximumRepairCostValidator')){
          <span slot="errormessage">{{headerForm.controls.equipmentNumber.getError('equipmentMaximumRepairCostValidator').value}}</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('equipmentLeaseValidator')){
          <span slot="errormessage">Equipment is not owned, leased or operated by Maersk, repair not allowed</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('equipmentTypeValidator')){
          <span slot="errormessage">Equipment type codes 'TWDK' and 'TWFL' are not allowed for containers</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('inactiveVSAandSOAgreementValidator')){
          <span slot="errormessage">Equipment type VSA/SO, repair not allowed</span>
          }
          @else if(headerForm.controls.equipmentNumber.hasError('equipmentLiveRepairValidator')){
          <span slot="errormessage">Container is not empty, only high or medium alarm allows for repairs</span>
          }
        </mc-input>
      </div>

      <div class="pure-u-md-8-24 pure-u-lg-10-24 p-b-16 p-r-8">
        <mc-select ngDefaultControl formControlName="mode" label="Mode" placeholder="Select an option" fit="small"
          [attr.invalid]="isControlInErrorState(headerForm.controls.mode)"
          [class.custom-error]="errorControls().indexOf('mode') >=0">
          @for (mode of filteredModes; track $index) {
          <mc-option [value]="mode">{{ mode.code }} {{ mode.description }}</mc-option>
          }
        </mc-select>
      </div>
      <div class="pure-u-md-4-24 pure-u-lg-2-24 p-b-16 p-r-8 m-t-28">
          <mc-checkbox [hidden]="!showMacOk" fit="small" ngDefaultControl formControlName="macOk" label="Mac OK" name="macOK">
          </mc-checkbox>
          <mc-checkbox [hidden]="!showBoxOk" fit="small" ngDefaultControl formControlName="boxOk" label="Box OK" name="boxOk">
          </mc-checkbox>
      </div>

      <div class="pure-u-md-6-24 pure-u-lg-8-24 p-b-16 p-r-8">
        <mc-input ngDefaultControl formControlName="vendorRefNo" label="Vendor Ref No. (Optional)"
          placeholder="Vendor Ref No." fit="small" [attr.invalid]="
            isControlInErrorState(headerForm.controls.vendorRefNo)
          ">
          @if(headerForm.controls.vendorRefNo.hasError('maxLength')) {
          <span slot="errormessage">Vendor Ref No. should not exceed 30 characters</span>
          }
        </mc-input>
      </div>

      <div class="pure-u-md-6-24 pure-u-lg-4-24 p-b-16 p-r-8">
        <mc-select ngDefaultControl formControlName="cause" label="Cause" placeholder="Select an option" fit="small">
          @for (cause of causes; track $index) {
          <mc-option [attr.data-cy]="cause.code" [value]="cause">
            {{ cause.displayText }}
          </mc-option>
          }
        </mc-select>
      </div>
      @if(isThirdPartyDamage()){
      <div class="pure-u-md-8-24 pure-u-lg-10-24 p-b-16 p-r-8">
        <mc-input ngDefaultControl label="3rd Party Port" formControlName="thirdPartyPort" placeholder="Type to search"
          fit="small" [attr.invalid]="isControlInErrorState(headerForm.controls.thirdPartyPort)">
          @if(headerForm.controls.thirdPartyPort.hasError('required')) {
            <span slot="errormessage">3rd Party Port is required</span>
            }
        </mc-input>
      </div>
      }
      <div class="pure-u-md-4-24 pure-u-lg-2-24 p-b-16 p-r-8 m-t-20">
        <div>{{headerForm.controls.currency.value}}</div>
        <div class="mds-neutral--weak__text-color mds-text--x-small-normal" style="line-height: 10px;">Currency</div>
      </div>
    </div>
  </div>
</form>
} @else{

<div class="aems-card m-b-12">
  <div class="pure-g">
    @for(n of [1,2,3,4,5,6];track $index){
    <div class="pure-u-md-12-24 p-t-12 pure-u-lg-6-24 p-b-16 p-r-8">
      <ngx-skeleton-loader [theme]="{ height: '30px' }" count="1" appearance="line" />
    </div>
    }
  </div>
</div>
}