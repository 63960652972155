
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { utils } from '../helper/utils';
import { BehaviorSubject, Observable, concatAll, debounce, debounceTime, filter, interval, map, mergeMap, of, shareReplay, tap, throttle } from 'rxjs';
import { CodeDescription } from '../models/code-description.model';
import { Equipment } from '../models/equipment.model';
import { Shop } from '../models/shop.model';
import { Repair } from '../models/repair.model';
import { Mode, DamageType } from '../models/mode.model';
import { Part } from '../models/part.model';
import { EquipmentRemark } from '../models/equipment-remark.model';
import { ContainerGrade } from '../models/container-grade.model';
import { User } from '../models/user.model';
import { DamageCause } from '../models/damage-cause.model';
import { ThirdPartyIndicator } from '../models/tpi.model'
import { MaintenanceOrderStatus, MaintenanceOrderStatusCode } from '../models/maintenance-order-status.model';
import { ShopRepairCostAllocation } from '../models/shop-repair-cost-allocation.model';
import { RepairLocation } from '../models/repair-location.model';
import { EquipmentAlarm } from '../models/equipment-alarm.model';
import { EquipmentPtiStatus } from '../models/equipment-pti-status.model';
import { RepairExclusion } from '../models/repair-exclusion.model';
@Injectable({
  providedIn: 'root',
})
export class MasterDataService {
  constructor(private client: HttpClient) { }


  public getShopsByShopCode(shopCode: string): Observable<Shop> {
    return this.client.get<Shop>(utils.GetMasterDataUrl(`shops/${shopCode}`));
  }

  public getShopRepairCostLimitByMode(shopCode: string, repairMode: string): Observable<ShopRepairCostAllocation> {
    return this.client.get<ShopRepairCostAllocation>(utils.GetMasterDataUrl(`shops/${shopCode}/cost-limit?repairMode=${repairMode}`));
  }


  public getDamageCauses(): Observable<DamageCause[]> {
    const sequence = [
      "WEAR_AND_TEAR",
      "HANDLING_DAMAGE",
      "CONFIRMED_3RD_PARTY",
      "UNCONFIRMED_3RD_PARTY"
    ];

    return this.client.get<DamageCause[]>(utils.GetMasterDataUrl('damage-causes')).pipe(
      map((list: DamageCause[]) => {
        return list.map((damageCause: DamageCause) => {
          damageCause.displayText = `${sequence.indexOf(damageCause.code) + 1} - ${damageCause.displayText}`;
          return damageCause;
        }).sort((a, b) => a.displayText.localeCompare(b.displayText));
      })
    );
  }

  public getRepairModes(): Observable<Mode[]> {
    return this.client.get<Mode[]>(utils.GetMasterDataUrl('repair-modes'));
  }
  public getRepairMode(repairModeCode:string): Observable<Mode> {
    return this.client.get<Mode>(`${utils.GetMasterDataUrl('repair-modes')}/${repairModeCode}`);
  }

  public getMaintenanceOrderStatus(): Observable<MaintenanceOrderStatus[]> {
    const statuses: MaintenanceOrderStatus[] = Object.values(MaintenanceOrderStatusCode)
      .filter((value): value is MaintenanceOrderStatusCode => typeof value === 'number')
      .map(statustype => ({
        code: statustype,
        description: utils.getMaintenanceOrderStatusText(statustype).description
      }));

    const finalStatuses: MaintenanceOrderStatus[] = [];
    statuses.forEach(item => {
      if (item.code === undefined) {
        return;
      }

      
      let existingStatus = finalStatuses.find(x => x.description === item.description);
      if (!existingStatus) {
        finalStatuses.push({
          generatedCode: utils.getMaintenanceOrderStatusText(item.code).generatedCode,
          description: item.description
        });
      }
    });


    return of(finalStatuses);
  }

  public getDamageCodes(modeType: DamageType | undefined): Observable<CodeDescription[]> {
    return modeType === undefined ? of([]) :
      this.client.get<CodeDescription[]>(`${utils.GetMasterDataUrl('damage-codes')}?damageType=${modeType}`);
  }
  public getRepairLocations(): Observable<RepairLocation[]> {
    return this.client.get<RepairLocation[]>(utils.GetMasterDataUrl('repair-locations')).pipe(
      map((repairLocationList: RepairLocation[]) => repairLocationList.map((repairLocation: RepairLocation) => {
        repairLocation.description = repairLocation.name;
        return repairLocation;
      }))
    );;
  }

  public getContainerGrades(): Observable<ContainerGrade[]> {
    return this.client.get<ContainerGrade[]>(utils.GetMasterDataUrl('container-grades'));
  }

  public getRepairCodes(repairMode: string | undefined, shopCode: string | undefined, exchangeRateFromUsd: number): Observable<Repair[]> {
    return repairMode === undefined || shopCode === undefined ? of([]) : this.client.get<Repair[]>(
      `${utils.GetMasterDataUrl(`shops/${shopCode}/repair-codes`)}?repairMode=${repairMode}`).pipe(
        map((repairList) => repairList.map((repair) => {
          repair.code = repair.repairCode;
          if(repair.isShopCurrency==false){
            repair.isShopCurrency=true;
            repair.maxMaterialCost = repair.maxMaterialCost * exchangeRateFromUsd;
          }
          return repair;
        }))
      );
  }

  public getRepairExclusions(repairMode: string): Observable<RepairExclusion[]> {
    return this.client.get<RepairExclusion[]>(`${utils.GetMasterDataUrl(`repair-exclusions`)}?repairMode=${repairMode}`);
  }

  public getRepairTpiCodes(): Observable<ThirdPartyIndicator[]> {
    return this.client.get<ThirdPartyIndicator[]>(utils.GetMasterDataUrl('repair-tpi-codes'));
  }

  public getRepairParts(repairMode: string | undefined, repairCode: string | undefined, shopCode: string | undefined, exchangeRateFromUsd: number): Observable<Part[]> {
    return repairMode === undefined || repairCode === undefined || shopCode === undefined ? of([]) : this.client.get<Part[]>(
      `${utils.GetMasterDataUrl(`shops/${shopCode}/repair-code/${repairCode}/parts`)}?repairMode=${repairMode}`
    ).pipe(
      map((partList: Part[]) => partList.map((part: Part) => {
        part.code = part.partCode;
        part.description = part.partDescription;
        part.basePricePerUnit = part.basePricePerUnit * exchangeRateFromUsd;
        part.finalPriceAfterDiscountAndMarkupPerUnit = part.finalPriceAfterDiscountAndMarkupPerUnit * exchangeRateFromUsd;
        return part;
      }))
    );;
  }

  public getEquipmentRemark(equipmentNumber: string): Observable<EquipmentRemark | null> {
    return this.client.get<EquipmentRemark>(
      `${utils.GetMasterDataUrl(`equipment-data/${equipmentNumber}/remarks`)}`
    );
  }

  public getEquipmentDetails(equipmentNumber: string, locationCode?: string): Observable<Equipment> {
    let url: string = `${utils.GetUrl('equipment-details')}/${equipmentNumber}`;
    if (locationCode) {
      url += `?locationCode=${locationCode}`
    }
    return this.client
      .get<Equipment>(url);
  }

  public getEquipmentAlarms(equipmentNumber: string): Observable<EquipmentAlarm[]> {
    return this.client
      .get<EquipmentAlarm[]>(
        `${utils.GetUrl('equipment-details')}/${equipmentNumber}/alarms`
      )
    }

    public getEquipmentPtiStatus(equipmentNumber: string): Observable<EquipmentPtiStatus> {
      return this.client
        .get<EquipmentPtiStatus>(
          `${utils.GetUrl('equipment-details')}/${equipmentNumber}/pti-status`
        )
      }
  public getUser(): Observable<User> {
    return this.client.get<User>(utils.GetUrl('identity/user-details'));
  }
}
