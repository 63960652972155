<form [formGroup]="repairLineItemsForms">

  <div class="aems-card line-item-container m-b-12" formArrayName="repairLineItems">
    @if(modeAndShopSelected() && apiCallsForMasterData()==="COMPLETED_SUCCESFULLY"){
    @for (repairLineItem of repairLineItems.controls; track repairLineItem ;let i= $index) {
    <app-bulk-work-order-repair-line-item [repairLineItemForm]="repairLineItem" [index]="i" [shop]="selectedShop()"
      [damages]="damages()" [repairs]="repairs()" [repairLocations]="repairLocations()" [tpiCodes]="tpiCodes()"
      (repairLineItemRemoved)="onRemoveRepairLineItem($event)"
      [class.error-on-form-group]="repairLineItem.hasError('repairCodeAndRepairLocationUnique') || repairLineItem.hasError('mutualExclusiveRepairCode')"
      [controlId]="'repair-line-item'+i"></app-bulk-work-order-repair-line-item>
    }
    <div class="add-new-line-item-container">
      <mc-button class="m-t-20" label="Add repair item" variant="outlined" appearance="primary" fit="small"
        padding="default" (click)="onAddRepairLineItemClicked()">
      </mc-button>
    </div>
    }@else {
    <app-loader width="100px" [count]="4"></app-loader>
    }
  </div>


</form>