<div class="mds parent-container">
  <div class="holder">
    @if (isLoginRedirect) {
    <div style="margin-top:100px;">
      @if(!userHasNoRole){
      <mc-loading-indicator label="Loading">
      </mc-loading-indicator>
    }@else {
      <mc-icon class="error-icon" icon="exclamation-circle"></mc-icon>
    }
    </div>
    }
    <div [class.message]="!userHasNoRole">{{message}}</div>
    @if(!isLoginRedirect){
    <div>Logging out...</div>
    }
</div>