import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';
import { utils } from '../../../../helper/utils';
import { RepairLineItemsForm } from '../../../../form-model/damage-and-repair.form.model';
export function reapirCodeAndRepairLocationUniqueValidator(): ValidatorFn {
	return (control: AbstractControl) => {
		let damageAndRepairForm = control as FormGroup<RepairLineItemsForm>;
		let repairLineItems = damageAndRepairForm.controls.repairLineItems;
		let repairCodesAndLocations: { uniqueCode: string }[] = [];
		repairLineItems.controls.forEach((repairLineItem) => {
			let repairCode = repairLineItem.controls.repairCode.value;
			let repairLocation = repairLineItem.controls.repairLocationCode.value;
			repairCodesAndLocations.push({ uniqueCode: repairCode + repairLocation });
		});
		var duplicateFound = utils.checkForDuplicates(repairCodesAndLocations, 'uniqueCode');
		if (duplicateFound) {
			let existingErrors = repairLineItems.controls[repairLineItems.controls.length - 1].errors;
        	if(existingErrors){
            	existingErrors['repairCodeAndRepairLocationUnique'] = { value: 'Duplicate line item added' };
        	}
			else{
				repairLineItems.controls[repairLineItems.controls.length - 1].setErrors({
					repairCodeAndRepairLocationUnique: { value: 'Duplicate line item added' },
				});
			}
			return { repairCodeAndRepairLocationUnique: { value: 'Duplicate line item added' } };
		} else {
			repairLineItems.controls.forEach((repairLineItem) => {
				if (repairLineItem.errors) {
					const errors = { ...repairLineItem.errors }; // Create a copy of the current errors

  					delete errors['repairCodeAndRepairLocationUnique']; // Remove the specific error

  					// If no more errors, clear the errors object completely, otherwise set the remaining errors
  					repairLineItem.setErrors(Object.keys(errors).length ? errors : null);
				}
			});
			return null;
		}
	};
}
