import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import '@maersk-global/mds-components-core/mc-icon';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-label';
import '@maersk-global/mds-components-core/mc-toast';
import { FileViewerComponent } from '../file-viewer/file-viewer.component';
import { AemsFile } from '../../../models/aems-file.model';
import { AemsFileValidation } from '../validators/file-upload.validator';
import { ToastrService } from '../../../services/toastr-service';
import { DragDropDirective } from '../../../directives/drag-drop.directive';
import { TelemetryService } from '../../../services/telemetry.service'; // Import TelemetryService

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [CommonModule, FileViewerComponent,DragDropDirective],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInput {
  @Output() onFilesAttached=new EventEmitter<File[]>
  type = input.required<'full' | 'compact'>();
  @Input() files:AemsFile[] =[];
  constructor(
    private toastrService: ToastrService,
    private telemetryService: TelemetryService // Inject TelemetryService
  ) {}

   onFileChange(event: Event) {
    const control = event.target as HTMLInputElement;
    if(control){
      const incomingFiles = Array.from(control?.files ?? []);
      this.validateAndEmitFiles(incomingFiles);
      console.log('file change');
      this.telemetryService.logFileEvent('file-change', incomingFiles.length); // Log file change event
      control.value = '';
    }
   }



  onFileDroped(event:FileList){
    const incomingFiles = Array.from(event);
    this.validateAndEmitFiles(incomingFiles);
    console.log('file drop');
    this.telemetryService.logFileEvent('file-drop', incomingFiles.length); // Log file drop event
  }
  
  private validateAndEmitFiles(files: File[]) {
    var sanatizedIncomingFiles = new AemsFileValidation(files,this.toastrService)
      .validateFileCount(this.files.length)
      .validateFileSize()
      .validateDuplicateFiles(this.files)
      .validateFileExtension()
      .get();
      this.onFilesAttached.emit(sanatizedIncomingFiles);   
  }
}
