import { Injectable } from '@angular/core';
import { Mode } from '../models/mode.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Shop } from '../models/shop.model';

@Injectable({
  providedIn: 'root'
})
export class BulkWorkOrderSharedDataService {

  private mode$ = new BehaviorSubject<Mode|null>(null);
  get Mode(): Observable<Mode|null> {
    return this.mode$.asObservable();
  }
  set Mode(mode: Mode) {
    this.mode$.next(mode);
  }

  private shop$ = new BehaviorSubject<Shop|null>(null);
  get Shop(): Observable<Shop|null> {
    return this.shop$.asObservable();
  }
  set Shop(shop: Shop) {
    this.shop$.next(shop);
  }
}
