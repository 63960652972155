import {
	Component,
	CUSTOM_ELEMENTS_SCHEMA,
	input,
	Output,
	EventEmitter,
	Input,
	ChangeDetectionStrategy,
} from '@angular/core';
import '@maersk-global/mds-components-core/mc-modal';
import '@maersk-global/mds-components-core/mc-button';
import '@maersk-global/mds-components-core/mc-icon';
import { AemsFile } from '../../../models/aems-file.model';
import { DomSanitizer } from '@angular/platform-browser';
import { BlobService } from '../../../services/blob.service';
import { ToastrService } from '../../../services/toastr-service';

@Component({
	selector: 'app-file-viewer',
	standalone: true,
	imports: [],
	templateUrl: './file-viewer.component.html',
	styleUrl: './file-viewer.component.scss',
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	changeDetection:ChangeDetectionStrategy.OnPush
})
export class FileViewerComponent {
	@Input() openFileViewer = false;
	@Input() disableNext = false;
	@Input() disablePrevious = false;
	@Output() fileViewerClosed = new EventEmitter<boolean>();
	@Output() fileDelete = new EventEmitter<AemsFile>();
	@Output() nextClicked = new EventEmitter();
	@Output() previousClicked = new EventEmitter();
	@Input({ required: true }) file!: AemsFile;
	@Input() readOnly=false;
	deleting: boolean = false;
	constructor(
		public sanitizer: DomSanitizer,
		private blobService: BlobService,
		private toastrService: ToastrService
	) { }
	closeFileViewer() {
		this.fileViewerClosed.emit(true);
	}
	onDeleteFileClick() {
		if (!this.file.uploadedToBlob) {
			this.fileDelete.emit(this.file);
			this.closeFileViewer();
		} else {
			this.deleting = true;
			this.blobService.deleteFile(this.file.generatedName??"", this.file.shopCode).subscribe({
				next: (response) => {
					this.closeFileViewer();
					this.fileDelete.emit(this.file);
					this.toastrService.showToastr('File deleted successfully', 'success');
					this.deleting = false;
				},
				error: (error) => {
					console.error(error);
					this.toastrService.showToastr('Failed to delete file', 'warning');
					this.closeFileViewer();
					this.deleting = false;
				},
			});
		}

	}
	onNextClicked() {
		this.nextClicked.emit();
	}
	onPreviousClicked() {
		this.previousClicked.emit();
	}
}
