@if(readOnly){
@if(inputTags.length>0){
<div class="tagged-input-parent">
  <mc-tag>{{ inputTags[0] }}</mc-tag>
  @if(inputTags.length>1){
  <mc-popover trigger="click" position="bottom-left">
    <div slot="trigger" class="overflow-count overflow-count-readonly">+{{inputTags.length-1}}</div>
    @if(inputTags){
    <div class="tags-container">
      @for(tag of inputTags;track tag){
      @if($index>0){
      <mc-tag>{{ tag }}</mc-tag>
      }
      }
    </div>
    }
  </mc-popover>
  }
</div>
}@else {
<div>-</div>
}
}@else{
<div>
  <mc-input class="tag-input" style="position: relative" [hiddenlabel]="hiddenLabel" [label]="label"
    [labelposition]="labelPosition" [fit]="fit" ngDefaultControl [(ngModel)]="inputControlValue"
    (keydown.enter)="onEnterPressed($event)" placeholder="Hit enter to add mutiple values" (blur)="onBlur()"
    [attr.invalid]="hasDuplicateTag?true:null">
    @if(hasDuplicateTag){
    <span slot="errormessage">Duplicate entry</span>
    }
    @if(inputTags.length>0){
    <span slot="hint">
      <mc-popover trigger="click" position="bottom-left">
        <div slot="trigger" class="overflow-count" [class.label-left]="labelPosition==='left'||hiddenLabel===true">
          +{{inputTags.length}}</div>
        @if(inputTags){
        <div class="tags-container">
          @for(tag of inputTags;track tag){
          <mc-tag withaction (dismiss)="removeTag($index)">{{ tag }}</mc-tag>
          }
        </div>
        }
      </mc-popover>
    </span>

    }
  </mc-input>
</div>
}