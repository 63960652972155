import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, signal } from '@angular/core';
import { Theme } from './theme';
import { toObservable } from '@angular/core/rxjs-interop';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class ThemeService {
    private style!: HTMLLinkElement;
    private themeCssId:string="theme-css-id";
    private theme$=new BehaviorSubject<Theme>(Theme.LIGHT);
    constructor(@Inject(DOCUMENT) private document: Document) {

    }
    setTheme(theme: Theme,renderer:Renderer2) {
        this.removeExisitingTheme(renderer);
        this.style=renderer.createElement('link')as HTMLLinkElement;
        renderer.setProperty(this.style,'rel','stylesheet');

        // The CSS file name has to match with the bundle name given in angular.json for the theme css files
        renderer.setProperty(this.style,'href',`${theme}.css`); 

        // Assign an id to the link element so that it can be removed when the theme is changed
        renderer.setProperty(this.style,'id',this.themeCssId);

        // Add the link element to the head of the document for theme changes to take effect
        renderer.appendChild(this.document.head,this.style);
        this.theme$.next(theme)
    }
    get theme():Observable<Theme>{
        return this.theme$.asObservable();
    }
    private removeExisitingTheme(renderer:Renderer2){
        const themeIDHtmlElement=this.document.getElementById(this.themeCssId);
        if(themeIDHtmlElement){
            renderer.removeChild(this.document.head,themeIDHtmlElement);
        }
    }
}
